import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import resort from '../assets/images/resort.svg'
import parse from 'html-react-parser'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import sustainabilityManagementPdf from '../assets/documents/AKM-Sustainability-2023.pdf';
import environmentalPolicyPdf from '../assets/documents/AKM-Environmental-2023.pdf';

function ExperienceAwait({heading="A stay that stays with you", para,image=resort,showLearnMore=true, showHeading=true, visitLinks=false}, absd=true) {
    const smallHeading = ['WELCOME TO ATMOSPHERE HOTELS & RESORTS'];
    
  return (
    <>
        <section>
            <div className='atmosphere-experience-await section-padding'>
                <div className='container'>
                    <div className='row'>

                        <div className='d-none'>
                            
                        </div>


                        <div className='col-12 text-center'>
                            <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                                <img src={image} title='Atmosphere Living' alt='Atmosphere Living' />
                            </div>
                            {showHeading? (<div className='small-heading'>
                                <span className='text-uppercase'>{smallHeading}</span>
                            </div>):("")}
                            
                                                        
                            <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{heading}</h1>
                        </div>
                        <div className='col-12'>
                            <div className='paragraph pt-3 text-center'>
                                <p>
                                    {parse(para)}
                                </p>
                            </div>
                            {showLearnMore? (<div className='action-btn mt-lg-5 mt-4 text-center'>
                                <Link to='/our-story' className="btn theme-btn fw-medium text-white text-uppercase border-theme">Our Story</Link>
                            </div>):("")}

                            {visitLinks? (<div className='site-visit-links d-flex flex-wrap justify-content-center mt-xl-5 mt-4'>
                                <Link to={sustainabilityManagementPdf} target="_blank" className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme">Sustainability Management <FontAwesomeIcon icon={faArrowUp} className='ms-2' /></Link>
                                <Link to={environmentalPolicyPdf} target="_blank" className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme ms-md-2 ms-lg-4 ms-0 mt-md-0 mt-3">Environmental Policy <FontAwesomeIcon icon={faArrowUp} className='ms-2' /></Link>
                            </div>):("")}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ExperienceAwait