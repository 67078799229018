import React  from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane, faPhone} from '@fortawesome/free-solid-svg-icons'
import FooterLogo from '../assets/images/footer-logo.png'
import AtmosphereCore from '../assets/images/atmosphere-core-logo.svg'
import TwitterX from '../assets/images/twitter-x.svg'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Footer() {

 
  return (
        <>
            <footer>
                <div className='footer-newsletter py-4'>
                    <div className='container'>
                        <div className='row my-2'>     
                            <div className='col-12'>
                                <div className='d-flex align-items-center flex-md-nowrap flex-wrap justify-content-center'>
                                <h6 className='text-uppercase georgia m-0 text-white'>SIGN UP FOR OUR NEWSLETTER</h6>
                                <form action='#' method='get'>
                                    <div className='position-relative d-flex'>
                                        <Form.Control type="email" className='bg-transparent text-white shadow-none' placeholder="Enter your email address..." />
                                        <Button className='d-flex justify-content-center align-items-center text-uppercase' id="button-addon2"><FontAwesomeIcon icon={faPaperPlane} className='icon-font' /> Send</Button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /footer-newsletter */}
                <div className='mein-footer'>
                    <div className='footer-wrap'>
                        <div className='row align-items-center'>
                            <div className='col-xxl-4 col-xl-3'>
                                <div className='footer-logo'>
                                    <Image src={FooterLogo} alt='Atmosphere' title='Atmosphere' />
                                </div>
                            </div>
                            {/* /col-lg-3 */}
                            <div className='col-xxl-4 col-xl-4 col-md-6'>
                                <div className='footer-col d-flex justify-content-center no-border mt-2 mt-md-0 mb-3 mb-md-0'>
                                    <div className='footer-links text-center'>
                                        <Link to="https://www.atmosphere-kanifushi.com/privacy-policy/?_gl=1*1uubhgo*_ga*MTMzMjcwOTkwNS4xNjk4OTE2ODU2*_ga_5D7JCP2H7F*MTcwMDU1ODI0NS42LjAuMTcwMDU1ODI0NS42MC4wLjA" target='_blank' onClick={()=>window.scrollTo(0,0)}><span>Terms of Use</span></Link>
                                        <Link to="https://www.atmosphere-kanifushi.com/privacy-policy/?_gl=1*1uubhgo*_ga*MTMzMjcwOTkwNS4xNjk4OTE2ODU2*_ga_5D7JCP2H7F*MTcwMDU1ODI0NS42LjAuMTcwMDU1ODI0NS42MC4wLjA" target='_blank' onClick={()=>window.scrollTo(0,0)}><span>Privacy Policy</span></Link>
                                        {/* <Link to="/coming-soon" onClick={()=>window.scrollTo(0,0)}><span>GDPR Compliance</span></Link> */}
                                        <Link to="/media-center"><span>Media Center</span></Link>
                                        <Link to="/contact-us"><span>Contact</span></Link>
                                    </div>
                                </div>
                            </div>
                            {/* /col-lg-3 */}
                            <div className='col-xxl-4 col-xl-5 col-md-6'>
                                <div className='footer-col d-flex justify-content-end'>
                                    <div className='location d-flex align-items-center'>
                                        <div className='phone-footer text-center email-footer'>
                                            <span className='icon-phone'><FontAwesomeIcon icon={faPhone} className='icon-font' /></span> 
                                            <Link to="tel:+919876543218">+91 9876543218</Link>
                                        </div>
                                        <div className='phone-footer ms-lg-5 ms-4 text-center email-footer'>
                                            <span className='icon-phone'><FontAwesomeIcon icon={faEnvelope} className='icon-font' /></span> <Link to="mailto:info@atmosphere.com">info@atmosphere.com</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /col-lg-3 */}
                        </div>
                    </div>
                    {/* /footer-wrap */}
                    <div className='bottom-footer d-flex justify-content-between align-items-center'>
                        <div className='copyright'>
                            <span>&copy; Atmosphere Hotels & Resorts 2023. All Rights Reserved. </span>
                            <span>Powered by Digitech.</span>
                            {/* <span>Powered by <Link to='https://www.digitechsoft.com/' target='_blank' className='text-decoration-none'>Digitech</Link>.</span> */}
                        </div>
                        <div className='footer-social'>
                            <Link to="https://facebook.com/atmospherecore" target='_blank'><FontAwesomeIcon icon={faFacebookF} className='icon-font' /></Link>
                            <Link className='d-none' href="/"><img src={TwitterX} alt='Twitter' title='Twitter' /></Link>
                            <Link to="https://www.linkedin.com/company/atmospherecore?originalSubdomain=mv" target='_blank'><FontAwesomeIcon icon={faLinkedinIn} className='icon-font' /></Link>
                            <Link to="https://www.instagram.com/atmospherecore" target='_blank'><FontAwesomeIcon icon={faInstagram} className='icon-font' /></Link>
                        </div>
                        <div className='atmosphere-core-logo'>
                            <Link to='https://www.atmospherecore.com/' target='_blank'><Image src={AtmosphereCore} alt='Atmosphere' title='Atmosphere' /></Link>                            
                        </div>
                    </div>
                </div>
                {/* /main-footer */}
            </footer>
        </>
  )
}

export default Footer