import React from 'react'
import HomeVideo from '../../components/HomeVideo'
import BookNow from '../../components/BookNow'
import ExperienceAwait from '../../components/ExperienceAwait'
import OurSubBrands from '../../components/OurSubBrands'
import HotelsResorts from '../../components/HotelsResorts'
import OurGallery from '../../components/OurGallery'
import SpecialOffers from '../../components/SpecialOffers'
import Sustainability from '../../components/Sustainability'

export const Home = () => {
  const para = `<p>Staying in a hotel is more than a mere check-in; it’s about signing up for an experience outside of what you’ve had before in life. It’s not a mere getaway but an opening to the possibility of feeling at home outside of your home. And in a world of sameness, a memorable hotel stay offers all this and more by blending in a different perspective to hospitality and luxury, one that’s enriching, authentic & crafted with attention to detail. Welcome to our immersive world of Atmosphere Hotels and Resorts, where exploring life finds new meanings. It is a stay that stays with you, even long after you check out.</p>`;
  
  return (
    <>
        <HomeVideo/>
        <BookNow/>
        <ExperienceAwait para={para}/>
        <OurSubBrands/>
        <HotelsResorts/>
        <OurGallery/>
        <SpecialOffers/>
        <Sustainability/>
    </>
  )
}
