import React from 'react'

function DiscoverRightItems(props) {
    const { discoverImage, itemsdetails } = props;
  return (
    <>
        <div className='discover-card'>
            <div className='discover-card-image'>
                <img className='w-100' src={discoverImage} alt='Discover' title='Discover' />
            </div>
            <div className='discover-card-body position-relative d-flex align-items-center justify-content-center flex-column'>
                <div className='discover-feature-icon rounded-circle d-flex align-items-center justify-content-center'>
                    <img src={itemsdetails[0].discoverIcon} alt='Icon' title='Icon' />
                </div>
                <div className='discover-title my-lg-3 mt-lg-4 my-2'>
                    {itemsdetails[0].discoverTitle}
                </div>
                <div className='discover-text text-center'>
                    <p>{itemsdetails[0].discoverCardPara}</p>
                </div>
                </div>
        </div>
    </>
  )
}

export default DiscoverRightItems