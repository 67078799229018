import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bottomStroke from '../assets/images/brush-stoke.png'
import { motion } from 'framer-motion';

function HotelsResortsMobileSlider({images,gallerySettings}) {
    const settings = {...gallerySettings}
  return (
    <>
        <Slider {...settings}>
            {images.map(image =>(
                 
                <div className='hotel-resort-mobile-slide position-relative'>
                    <div className='mob-slide-image position-relative'>
                        <img className='w-100' src={image.imageUrl} alt='Our Gallery' title='Our Gallery' />
                    </div>
                    <div className='mob-slide-caption text-center position-absolute start-0 end-0 top-0 bottom-0 d-flex align-items-center justify-content-center z-1'>
                        <motion.div 
                        initial={{ opacity: 1, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className='caption-wrap text-center text-white'>
                            <h1 className='text-uppercase georgia'>{image.title}</h1>
                            <span className='text-uppercase'>{image.heading}</span>
                            <div className='bottom-stroke my-4 d-flex justify-content-center'>
                                <img src={bottomStroke} alt='' title='' />
                            </div>
                            <small className='text-uppercase'>{image.desc}</small>
                        </motion.div>
                    </div>
                </div>
            ))}
        </Slider>
    </>
  )
}

export default HotelsResortsMobileSlider