import React from 'react'

function AwardCard({awardImage, awardLogo, awardCompanyName}) {
  return (
    <>
        <div className='award-card text-center rounded-3 border'>
            <div className='award-image'>
                <img src={awardImage} alt={awardCompanyName} title={awardCompanyName} />
            </div>
            <div className='award-by'>
                <div className='award-logo mt-lg-4 mb-2'>
                    <img src={awardLogo} alt={awardCompanyName} title={awardCompanyName} />
                </div>
                <span className='d-flex justify-content-center align-items-center m-auto'>{awardCompanyName}</span>
            </div>
        </div>
    </>
  )
}

export default AwardCard