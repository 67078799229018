import React from 'react'
import parse from 'html-react-parser'
import pressReleases from '../assets/images/awards-badge.svg'
import pdfIcon from '../assets/images/pdf.svg'
import downloadNow from '../assets/images/download.svg'
import CollateralCards from './CollateralCards'
import justVegKidsMenu from '../assets/collaterals/kanifushi/AKM-JUST-VEG-KIDS-MENU.pdf'
import sunsetDinnerMenu from '../assets/collaterals/kanifushi/AKM-SUNSET-DINNER-MENU.pdf'
import teppanyakiGrillMenu from '../assets/collaterals/kanifushi/AKM-TEPPANYAKI-GRILL-MENU-AT-PIER-SIX-SEP-2020.pdf'
import atmosphereKanifushiBrochure from '../assets/collaterals/kanifushi/Atmosphere-Kanifushi-Brochure-OCT-2023-WEB.pdf'
import atmosphereKanifushiPlan from '../assets/collaterals/kanifushi/Atmosphere-Kanifushi-The-Kanifushi-Plan-2023.pdf'
import atmosphereVaruBrochure from '../assets/collaterals/varu/VARU-by-Atmosphere-Brochure-OCT-2023.pdf'
import varuRenewalOfVows from '../assets/collaterals/varu/VARU-by-Atmosphere-Renewal-of-Vows-2023.pdf'
import varuCharcoalMenu from '../assets/collaterals/varu/VARU-CHARCOAL-MENU-NOV-2023.pdf'
import varuCigarMenu from '../assets/collaterals/varu/VARU-CIGAR-MENU-2023-JAN.pdf'
import varuExcursionSchedule from '../assets/collaterals/varu/Varu-Excursion-Schedule.pdf'
import varuNuMenu from '../assets/collaterals/varu/VARU-NU-MENU-NOV-2023.pdf'
import varuVaruPlan from '../assets/collaterals/varu/VARU-PLAN-2022-2023.pdf'

function MediaCollaterals({smallHeading = 'COLLATERALS',heading= 'OUR COLLATERALS'}) {
  const para = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"

  const kanifushTitle = "Atmosphere - Kanifushi"
  const varuTitle = "By Atmosphere - Varu"

  const collateralKanifushi = [
    {
      id: 1,
      docTypeIcon: pdfIcon,
      collateralTitle: "Kanifushi Just Veg Kids Menu",
      collateralPara: "Kids corn cheese burst",
      docDownloadIcon: downloadNow,
      downloadCollateral: justVegKidsMenu
    },
    {
      id: 2,
      docTypeIcon: pdfIcon,
      collateralTitle: "Kanifushi Sunset Dinner Menu",
      collateralPara: "Atmosphere Kanifushi Maldives",
      docDownloadIcon: downloadNow,
      downloadCollateral: sunsetDinnerMenu
    },
    {
      id: 3,
      docTypeIcon: pdfIcon,
      collateralTitle: "Teppanyaki Grill Menu at Pier",
      collateralPara: "Teppanyaki Gill",
      docDownloadIcon: downloadNow,
      downloadCollateral: teppanyakiGrillMenu
    },
    {
      id: 4,
      docTypeIcon: pdfIcon,
      collateralTitle: "Atmosphere Kanifushi Brochure",
      collateralPara: "Welcome to our little slice of Paradise",
      docDownloadIcon: downloadNow,
      downloadCollateral: atmosphereKanifushiBrochure
    },
    {
      id: 5,
      docTypeIcon: pdfIcon,
      collateralTitle: "Atmosphere Kanifushi Plan",
      collateralPara: "A gorgeous island resort in the Lhaviyani Atoll",
      docDownloadIcon: downloadNow,
      downloadCollateral: atmosphereKanifushiPlan
    }
  ]

  const collateralVaru = [
    {
      id: 1,
      docTypeIcon: pdfIcon,
      collateralTitle: "Atmosphere VARU Brochure",
      collateralPara: "Embark on an Authentic",
      docDownloadIcon: downloadNow,
      downloadCollateral: atmosphereVaruBrochure
    },
    {
      id: 2,
      docTypeIcon: pdfIcon,
      collateralTitle: "VARU By Atmosphere Renewal of Vows",
      collateralPara: "Say'l do' again. Make everlasting memories in the Maldives",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuRenewalOfVows
    },
    {
      id: 3,
      docTypeIcon: pdfIcon,
      collateralTitle: "VARU by Atmosphere Charcoal Menu",
      collateralPara: "Just Appetite Choose any one",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuCharcoalMenu
    },
    {
      id: 4,
      docTypeIcon: pdfIcon,
      collateralTitle: "VARU by Atmosphere CIGAR",
      collateralPara: "All the above listed CIGAR are subject to available",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuCigarMenu
    },
    {
      id: 5,
      docTypeIcon: pdfIcon,
      collateralTitle: "VARU Excursion Schedule",
      collateralPara: "The venue may change based on weather conditions",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuExcursionSchedule
    },
    {
      id: 6,
      docTypeIcon: pdfIcon,
      collateralTitle: "VARU Nu Menu",
      collateralPara: "Ingredients may contain other allergens.",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuNuMenu
    },
    {
      id: 7,
      docTypeIcon: pdfIcon,
      collateralTitle: "Atmosphere VARU Plan",
      collateralPara: "The VARU premium holiday plan combines your entire stay into one seamless adventure.",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuVaruPlan
    }
  ]

  return (
    <>
        <div className='container'>
          <div className='row'>
              <div className='col-12 text-center'>
                <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                    <img src={pressReleases} title='Atmosphere Living' alt='Atmosphere Living' />
                </div>
                <div className='small-heading'>
                    <span className='text-uppercase'>{smallHeading}</span>
                </div>                      
                <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{heading}</h1>
                <div className='media-para m-auto pt-3 text-center'>
                  <p>
                    {parse(para)}
                  </p>
                </div>
              </div> 
              <div className='collaterals-cards mt-lg-5 mt-4'>
                <div className='row gy-4'>
                  <div className='col-12'>
                    <h4 className='mb-0 lh-1'>{kanifushTitle}</h4>
                  </div>
                  {collateralKanifushi.map((collateralCard) =>(
                    <div className='col-lg-6'>
                      <CollateralCards
                        key={collateralCard}
                        docTypeIcon={collateralCard.docTypeIcon}
                        collateralTitle={collateralCard.collateralTitle}
                        collateralPara={collateralCard.collateralPara}
                        docDownloadIcon={collateralCard.docDownloadIcon}
                        downloadCollateral={collateralCard.downloadCollateral}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* collaterals-cards */}
              
              <div className='collaterals-cards mt-lg-5 mt-4'>
                <div className='row gy-4'>
                  <h4 className='mb-0 lh-1'>{varuTitle}</h4>
                  {collateralVaru.map((collateralCard) =>(
                    <div className='col-lg-6'>
                      <CollateralCards
                        key={collateralCard}
                        docTypeIcon={collateralCard.docTypeIcon}
                        collateralTitle={collateralCard.collateralTitle}
                        collateralPara={collateralCard.collateralPara}
                        docDownloadIcon={collateralCard.docDownloadIcon}
                        downloadCollateral={collateralCard.downloadCollateral}
                      />
                    </div>
                  ))}
                </div>
              </div>

            </div>
        </div>
    </>
  )
}

export default MediaCollaterals