import React from 'react'
import GallerySlides from './GallerySlides'
import galleryImgOne from '../assets/images/gallery-img-1.jpg'
import galleryImgTwo from '../assets/images/gallery-img-2.jpg'
import galleryImgThree from '../assets/images/gallery-img-3.jpg'
import galleryImgFour from '../assets/images/gallery-img-1.jpg'
import galleryImgFive from '../assets/images/gallery-img-2.jpg'
import galleryImgSix from '../assets/images/gallery-img-3.jpg'

function OurGallery() {
    const gallerySettings = {
        arrow: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    };

    const images = [{
        imageUrl:galleryImgOne,galleryHeading:"Inspired by Nature", galleryPara:"Our nature-inspired decor, services and experiences create an enriching and authentic connection with the locale."},

        {imageUrl:galleryImgTwo,galleryHeading:"ELE | NA The Spa", galleryPara:"An exceptional deep nature spa that blends local healing practices with international therapies for a unique spa experience."},
        
        {imageUrl:galleryImgThree,galleryHeading:"Wellness Retreats", galleryPara:"A personal journey of rejuvenation, tailored with your well-being in mind, invites you to unwind and rediscover your inner self."},

        {imageUrl:galleryImgFour,galleryHeading:"Inspired by Nature", galleryPara:"Our nature-inspired decor, services and experiences create an enriching and authentic connection with the locale."},

        {imageUrl:galleryImgFive,galleryHeading:"ELE | NA The Spa", galleryPara:"An exceptional deep nature spa that blends local healing practices with international therapies for a unique spa experience."},

        {imageUrl:galleryImgSix,galleryHeading:"Inspired by Nature", galleryPara:"Our nature-inspired decor, services and experiences create an enriching and authentic connection with the locale."}
    ]
  return (
    <>
        <section>
            <div className='atmosphere-gallery section-padding pt-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='small-heading my-xl-4 my-2 py-2'>
                                <span className='text-uppercase'>OUR GALLERY</span>
                            </div>
                            <h1 className='text-uppercase georgia heading-space'>GLIMPSES FROM OUR DESTINATIONS</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='gallery-slides mt-lg-5 mt-md-4 pt-md-0 pt-2'>
                            <GallerySlides images={images} classview='rounded-4' gallerySettings={gallerySettings}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default OurGallery