import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import './index.css';
import App from './App';
import { ScrollToTop } from './components/ScrollToTop';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>

      <Router>
        <ScrollToTop />
        <App />

      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);
