import React from 'react'
import ValueCardImgOne from '../assets/images/value-img-one.jpg'
import ValueCardImgTwo from '../assets/images/value-img-two.jpg'
import ValueCardImgThree from '../assets/images/value-img-three.jpg'
import { motion } from 'framer-motion';

function Card({ imageUrl, smalltitle, title, content }) {
    return (
      <motion.div 
      key={Math.random()}
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }} 
        className='col-lg-4 col-md-6'>
        <div className="value-card position-relative">
            <div className='value-card-img'>
                <img className='w-100' src={imageUrl} />
            </div>
            <div className='value-card-text position-absolute start-0 top-0 w-100'>
                <div className='value-card-title text-uppercase'>
                    <span className='text-white georgia'>{smalltitle}</span>
                    <h2 className='georgia text-white'>{title}</h2>
                </div>
                <p>{content}</p>
            </div>
        </div>
      </motion.div>
    );
}

const ValueCard = () => {
    const cardData = [
        { imageUrl: ValueCardImgOne,smalltitle: 'WE ARE',title: 'ENRICHING', content: "We explore new ways to define and enhance the bespoke nature of every guest experience within the Atmosphere brand. We are committed to crafting indulgent and enriching experiences that reflect and complement the unique natural habits embraced by our properties. "},
        { imageUrl: ValueCardImgTwo,smalltitle: 'WE ARE', title: "Immersive", content: "We curate distinctive experiences inspired by the local culture and tradition to kindle wanderlust, beckoning you to lose yourself in our destinations. Every stay is an invitation for a journey where you immerse yourself in our wonder-filled destinations and be charmed by the local surroundings." },
        { imageUrl: ValueCardImgThree,smalltitle: 'WE ARE', title: "AUTHENTIC", content: "We are inherently sincere, and with authenticity as our north star, we place the needs and desires of our guests at the heart of our endeavours. The hospitality experience at every property is the purest expression of time, traditions, and terrain, thus creating an environment that preserves the true essence of every destination."},
        // ... more card data ...
    ];
  return (
    <>
        {cardData.map((card, index) => (
            <Card
            key={index}  // Don't forget to add a unique key for each card when mapping over an array
            title={card.title}
            smalltitle={card.smalltitle}
            content={card.content}
            imageUrl={card.imageUrl}
            />
        ))}

    </>
  )
}

export default ValueCard