import React from 'react'
import FeatureCard from './FeatureCard'
import brandOne from '../assets/images/brand-1.jpg'
import brandTwo from '../assets/images/brand-2.jpg'
import brandThree from '../assets/images/brand-3.jpg'
import brandFour from '../assets/images/brand-4.jpg'
import brandFive from '../assets/images/brand-5.jpg'
import brandSix from '../assets/images/brand-6.jpg'
import brandSeven from '../assets/images/brand-7.jpg'
import AtmpsphereLiving from '../assets/images/logo-for-brand-card.png'
import AtmpsphereCore from '../assets/images/atmosphere-core.svg'
import SubBrandsSlides from './SubBrandsSlides'
import { keyboard } from '@testing-library/user-event/dist/keyboard'

function OurSubBrands() {
    const cardOneBg = 'cardOneBg';
    const cardTwoBg = "cardTwoBg";
    const cardThreeBg = 'cardThreeBg';
    const cardFourBg = 'cardFourBg';
    const cardFiveBg = 'cardFiveBg';
    const cardSixBg = 'cardSixBg';
    const cardSevenBg = 'cardSevenBg';
    const cardHeadingOne = 'ATMOSPHERE';
    const cardHeadingTwo = 'BY ATMOSPHERE';
    const cardHeadingThree = 'AN ATMOSPHERE EXPERIENCE';
    const cardHeadingFour = 'A SIGNATURE ATMOSPHERE';
    const cardHeadingFive = 'ELEMENTS OF NATURE BY ATMOSPHERE';
    const cardHeadingSix = 'HERITAGE BY ATMOSPHERE';
    const cardHeadingSeven = 'AN URBAN ATMOSPHERE';
    const paraOne = "Embrace an ensemble of classic luxury, distinctive design, contemporary art, and meticulous functionality within this Atmosphere.";
    const paraTwo = "An Atmosphere enveloping you in the warmth of heartfelt hospitality, in a charming getaway where you're not just a guest, you're family.";
    const paraThree = "Immerse yourself in an Atmosphere interwoven with the threads of local culture and nature. Here, enduring luxury blends with timeless authenticity.";
    const paraFour = "Experience an Atmosphere of elegance and grandeur, guiding you on an enriching journey through time and history in our iconic properties.";
    const paraFive = "Step into an Atmosphere of a calm symphony in serene wellness retreats. Ideal for those seeking tranquillity, nestled in the lap of nature.";
    const paraSix = "Time travel in this Atmosphere into the mystical world of rich, ancient history through havelis and colonial bungalows. ";
    const paraSeven = "An Atmosphere of urban chic, connected to the rhythm of city life steeped in a unique blend of seamless comfort and modern convenience. ";
    const cardBorderOne = 'cardBorderOneBg';
    const cardBorderTwo = 'cardBorderTwoBg';
    const cardBorderThree = 'cardBorderThreeBg';
    const cardBorderFour = 'cardBorderFourBg';
    const cardBorderFive = 'cardBorderFiveBg';
    const cardBorderSix = 'cardBorderSixBg';
    const cardBorderSeven = 'cardBorderSevenBg';

    const discoverCardItems = [
        {id: 1, cardFeatureImage:brandOne, cardHeading:"ATMOSPHERE", cardParagraph:"Embrace an ensemble of classic luxury, distinctive design, contemporary art, and meticulous functionality within this Atmosphere.",cardBg:'cardOneBg',cardLinkName: 'Discover',cardUrl:'/our-brands-page'},

        {id: 2, cardFeatureImage:brandTwo, cardHeading:"BY ATMOSPHERE", cardParagraph:"An Atmosphere enveloping you in the warmth of heartfelt hospitality, in a charming getaway where you're not just a guest, you're family.",cardBg:'cardTwoBg',cardLinkName: 'Discover',cardUrl:''},

        {id: 3, cardFeatureImage:brandThree, cardHeading:"AN ATMOSPHERE EXPERIENCE", cardParagraph:"Immerse yourself in an Atmosphere interwoven with the threads of local culture and nature. Here, enduring luxury blends with timeless authenticity.",cardBg:'cardThreeBg',cardLinkName: 'Discover',cardUrl:''},

        {id: 4, cardFeatureImage:brandFour, cardHeading:"A SIGNATURE ATMOSPHERE", cardParagraph:"Experience an Atmosphere of elegance and grandeur, guiding you on an enriching journey through time and history in our iconic properties.",cardBg:'cardFourBg',cardLinkName: 'Discover',cardUrl:''},

        {id: 5, cardFeatureImage:brandFive, cardHeading:"ELEMENTS OF NATURE BY ATMOSPHERE", cardParagraph:"Step into an Atmosphere of a calm symphony in serene wellness retreats. Ideal for those seeking tranquillity, nestled in the lap of nature.",cardBg:'cardFiveBg',cardLinkName: 'Discover',cardUrl:''},

        {id: 6, cardFeatureImage:brandSix, cardHeading:"HERITAGE BY ATMOSPHERE", cardParagraph:"Time travel in this Atmosphere into the mystical world of rich, ancient history through havelis and colonial bungalows.",cardBg:'cardSixBg',cardLinkName: 'Discover',cardUrl:''},

        {id: 7, cardFeatureImage:brandSeven, cardHeading:"AN URBAN ATMOSPHERE", cardParagraph:"An Atmosphere of urban chic, connected to the rhythm of city life steeped in a unique blend of seamless comfort and modern convenience.",cardBg:'cardSevenBg',cardLinkName: 'Discover',cardUrl:''}
    ]

    const images = [
        {imageUrl:brandOne, galleryHeading:"ATMOSPHERE", galleryPara:"Embrace an ensemble of classic luxury, distinctive design, contemporary art, and meticulous functionality within this Atmosphere.",cardBg:'cardOneBg',linkText: 'Discover'},
        {imageUrl:brandTwo, galleryHeading:"BY ATMOSPHERE", galleryPara:"An Atmosphere enveloping you in the warmth of heartfelt hospitality in a charming getaway where you're not just a guest, you're family.",cardBg:'cardTwoBg',linkText: 'Discover'},
        {imageUrl:brandThree, galleryHeading:"AN ATMOSPHERE EXPERIENCE", galleryPara:"Immerse yourself in an Atmosphere interwoven with the threads of local culture and nature. Here, enduring luxury blends with timeless authenticity.", cardBg:'cardThreeBg',linkText: 'Discover'},
        {imageUrl:brandFour, galleryHeading:"A SIGNATURE ATMOSPHERE", galleryPara:"Experience an Atmosphere of elegance and grandeur, guiding you on an enriching journey through time and history in our iconic properties.",cardBg:'cardFourBg',linkText: 'Discover'},
        {imageUrl:brandFive, galleryHeading:"ELEMENTS OF NATURE BY ATMOSPHERE", galleryPara:"Step into an Atmosphere of a calm symphony in serene wellness retreats. Ideal for those seeking tranquillity, nestled in the lap of nature.",cardBg:'cardFiveBg',linkText: 'Discover'},
        {imageUrl:brandSix, galleryHeading:"HERITAGE BY ATMOSPHERE", galleryPara:"Time travel in this Atmosphere into the mystical world of rich, ancient history through havelis and colonial bungalows.",cardBg:'cardSixBg',linkText: 'Discover'},
        {imageUrl:brandSeven, galleryHeading:"AN URBAN ATMOSPHERE", galleryPara:"An Atmosphere of urban chic, connected to the rhythm of city life steeped in a unique blend of seamless comfort and modern convenience.",cardBg:'cardSevenBg',linkText: 'Discover'}
    ]

    const gallerySettings = {
        arrow: true,
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 576,
              settings: {
                centerMode: false,
              }
            }
          ]
    };

  return (
    <>
        <section>
            <div className='our-sub-brands section-padding pt-0'>
            <div className='container'>
                    {/* /row */}
                    <div className='row justify-content-center position-relative d-none d-md-flex'>
                        <div className='vertically-heading h-100 position-absolute text-nowrap me-auto m-0'>
                            <h1 className='text-uppercase georgia heading-space'>OUR SUB BRANDS</h1>
                        </div>
                        <div className='col-md-12'>
                            <div className='row gy-4'>
                                <div className='col-xl-4 col-md-6'>
                                    <div className='sub-brand-card d-flex align-items-center justify-content-center w-100 h-100 rounded-4'>
                                        <img src={AtmpsphereLiving} alt='Atmosphere Living' title='Atmosphere Living' />
                                    </div>
                                </div>
                                {discoverCardItems.map((discoverItem)=>(
                                <FeatureCard 
                                    key={discoverItem.id}
                                    cardFeatureImage={discoverItem.cardFeatureImage}
                                    cardBg={discoverItem.cardBg}                                    
                                    cardHeading={discoverItem.cardHeading}
                                    cardParagraph={discoverItem.cardParagraph}
                                    cardLinkName={discoverItem.cardLinkName}
                                    cardUrl={discoverItem.cardUrl}
                                />
                                ))}
                                <div className='col-xl-4 col-md-6'>
                                    <div className='sub-brand-card d-flex align-items-center justify-content-center w-100 h-100 rounded-4'>
                                        <img src={AtmpsphereCore} alt='Atmosphere Living' title='Atmosphere Living' />
                                    </div>
                                </div>
                            </div>
                            {/* /row */}
                        </div>
                        {/* /col-md-12 */}
                        
                    </div>
                    {/* /row */}

                    <div className="brands-slide d-md-none">
                        <div className='vertically-heading mb-2'>
                            <h1 className='text-uppercase georgia heading-space'>OUR SUB BRANDS</h1>
                        </div>
                        <SubBrandsSlides gallerySettings={gallerySettings} images={images} />
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default OurSubBrands