import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/media-cover.jpg'
import MediaTabs from './MediaTabs.js'
import { Link } from 'react-router-dom';

function Media() {

  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Media</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Media</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='sub-brands-tabs media-center-tabs section-padding'>
                <MediaTabs/>
            </div>
        </section>

    </>
  )
}

export default Media