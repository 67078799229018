

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import Slide1 from "../assets/images/hotel-resort-slide-1.jpg";
import Slide2 from "../assets/images/hotel-resort-slide-2.jpg";
import Slide3 from "../assets/images/hotel-resort-slide-3.jpg";
import Slide4 from "../assets/images/hotel-resort-slide-4.jpg";
import Slide5 from "../assets/images/hotel-resort-slide-5.jpg";
import mobSlide1 from '../assets/images/hotel-resort-mobile-slide-1.jpg'
import mobSlide2 from '../assets/images/hotel-resort-mobile-slide-2.jpg'
import mobSlide3 from '../assets/images/hotel-resort-mobile-slide-3.jpg'
import nextArrow from "../assets/images/next-arrow.svg";
import prevArrow from "../assets/images/prev-arrow.svg";
import { Button } from "bootstrap";
import HotelsResortsMobileSlider from "./HotelsResortsMobileSlider";

const images = [
  { imageUrl: Slide1, sliderHeading: "Kanifushi", sliderSmallTitle: "atmosphere", sliderLocation: 'Maldives' },
  { imageUrl: mobSlide2, sliderHeading: "Varu", sliderSmallTitle: "by atmosphere", sliderLocation: 'Maldives' },
  { imageUrl: mobSlide3, sliderHeading: "Marina Gate", sliderSmallTitle: "an atmosphere experience", sliderLocation: 'Dubai' }
]
var dataArray = [
  { imageUrl: Slide1,heading: 'ATMOSPHERE', title: 'KANIFUSHI', desc: 'MALDIVES', linkName: 'Explore', linkTarget: '_blank' , link: 'https://atmosphere-kanifushi.com/' },
  { imageUrl: Slide2,heading: 'by atmosphere', title: 'Varu', desc: 'MALDIVES', linkName: 'Explore', linkTarget: '_blank' , link: 'https://varu-atmosphere.com/' },
  { imageUrl: Slide3,heading: 'AN ATMOSPHERE EXPERIENCE', title: 'MARINA GATE', linkName: 'Coming Soon', linkTarget: '_self' , desc: 'DUBAI', link: '' },
  { imageUrl: Slide4,heading: 'A SIGNATURE ATMOSPHERE', title: 'LALGARGH PALACE ', linkName: 'Coming Soon', linkTarget: '_self' , desc: 'RAJASTHAN', link: '' },
  { imageUrl: Slide5,heading: 'ELEMENTS OF NATURE BY ATMOSPHERE', title: 'SINHARAJA RETREAT', desc: 'RAJASTHAN', linkName: 'Coming Soon', linkTarget: '_self' , link: '' },
];
const gallerySettings = {
  arrow: true,
  dots: true,
  infinite: true,
  speed: 1500,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    { 
      breakpoint: 576,
      settings: {
        centerMode: false,
      }
    }
  ]
};

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5];
const slidestext = ["KANIFUSHI", "VARU", "MARINA GATE", "LALGARgH PALACE", "SINHARAJA RETREAT"];

let movecount = 0;

const HotelsResortsSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [backgroundSlide, setbackgroundSlide] = useState(1);
  const [currentAnimation, setCurrentAnimation] = useState('initial'); // Set an initial animation state
  const [currentSlider1, setCurrentSlider1] = useState(0);
  const [currentSlider, setCurrentSlider] = useState(0);
  const [xcurrentSlider, setXCurrentSlider] = useState(0);
  const [ycurrentSlider, setYCurrentSlider] = useState(0);
  const [imgwcurrentSlider, setIMGWCurrentSlider] = useState(0);
  const [imghcurrentSlider, setIMGHCurrentSlider] = useState(0);
  const [textnextSlider, settextnextSlider] = useState(1);

  let sliderpercent = 0
  //let sliderpercent=100/slides.length+'%';
  // const handlenextButtonClick = (animationType) => {
  //   // const newSlide = (currentSlide + 1) % slides.length;
  //   const newSlide = (currentSlide + 1) ;
  //   //console.log(newSlide)
  //   setCurrentSlide(newSlide);
  //    setCurrentAnimation(animationType);
  // };

  // const handlepreviousButtonClick = (animationType) => {
  //   const newSlide = (currentSlide - 1 + slides.length) % slides.length;
  //   //const newSlide = (currentSlide - 1);
  //   //console.log(newSlide)
  //   setCurrentSlide(newSlide);
  //   setCurrentAnimation(animationType);
  // };
  useEffect(() => {
    // On page load, add a transition to the first slide-caption element
    const slideCaptions = document.querySelectorAll('.slide-caption');
    const firstSlideCaption = slideCaptions[textnextSlider]
    if (firstSlideCaption) {
      //firstSlideCaption.style.transition = 'transform 1s ease-in-out';
      firstSlideCaption.style.transform = 'translateY(40px)';
    }
    settextnextSlider(textnextSlider + 1)
    slideCaptions.forEach(function (slideCaption, index) {
      if (index != 0) {
        //slideCaption.style.display = "none";
        slideCaption.style.opacity = '0';
      }
      else {
        // slideCaption.style.display = "block";
        slideCaption.style.opacity = '1';
      }
    });
  }, []);
  const handlenextButtonClick = (animationType) => {
    const nextbuttonorg = document.getElementById('nextbuttonorg');
    const nextbuttondummy = document.getElementById('nextbuttondummy');
    nextbuttonorg.style.display = 'none';
    nextbuttondummy.style.display = 'block';

    const prevbuttonorg = document.getElementById('prevbuttonorg');
    const prevbuttondummy = document.getElementById('prevbuttondummy');
    prevbuttonorg.style.display = 'none';
    prevbuttondummy.style.display = 'block';

    const newSlide = (currentSlide + 1) % slides.length;
    if (newSlide == 0)
      //setCurrentSlide(slides.length);
      setCurrentSlide(prevCount => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return slides.length;
      });
    else
      //setCurrentSlide(newSlide);
      setCurrentSlide(prevCount => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return newSlide;
      });
    setCurrentAnimation(animationType);
    getaxis();
    handleMoveLeft();
    handletextchange();
    setTimeout(() => {
      const nextbuttonorg = document.getElementById('nextbuttonorg');
      const nextbuttondummy = document.getElementById('nextbuttondummy');
      nextbuttonorg.style.display = 'block';
      nextbuttondummy.style.display = 'none';

      const prevbuttonorg = document.getElementById('prevbuttonorg');
      const prevbuttondummy = document.getElementById('prevbuttondummy');
      prevbuttonorg.style.display = 'block';
      prevbuttondummy.style.display = 'none';
    }, 1500);
  };

  function handletextchange() {
    const slideCaptions = document.querySelectorAll('.slide-caption:not([style*="opacity: 0"])');
    const slideCaptionshidden = document.querySelectorAll('.slide-caption:not([style*="opacity: 1"])');
    const firstSlideCaption = slideCaptions[0];
    if (firstSlideCaption) {
      firstSlideCaption.style.transition = 'opacity 0.4s ease-in-out';
      firstSlideCaption.style.opacity = '0';
      firstSlideCaption.style.zIndex = '-1';
      // Optionally, you can remove the element after the fade-out effect
      setTimeout(() => {
        // firstSlideCaption.style.display = 'none';
      }, 1000); // Adjust the timeout based on the duration of the transition
    }

    slideCaptionshidden.forEach(function (firstSlideCaptionhidden, index) {
      firstSlideCaptionhidden.style.transform = 'translateY(40px)';
    });

    const slideCaptionhidden = slideCaptionshidden[0];
    if (slideCaptionhidden) {
      slideCaptionhidden.style.transition = 'opacity 0.25s ease-in-out 0.2s,transform 1.25s ease-in-out 0s';
      slideCaptionhidden.style.transform = 'translateY(0px)';
      slideCaptionhidden.style.opacity = '1';
      slideCaptionhidden.style.zIndex = '1';
      // After a delay, apply the final style
      setTimeout(function () {
        var moveableDiv = document.getElementById('initialhw1');
        moveableDiv.appendChild(firstSlideCaption);
        //firstSlideCaption.style.transform = 'translateY(120px)';
      }, 1500);// Adjust the timeout based on the duration of the transition
    }
    settextnextSlider(textnextSlider + 1)
    if (textnextSlider == slides.length) {
      settextnextSlider(1)
    }
  }
  
  function getaxis() {
    var moveableDiv = document.getElementById('moveableDiv');
    var referenceDiv = document.getElementById('referenceDiv');

    var thumbWraps = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 0"])');

    if (thumbWraps.length > 0) {
      var firstThumbWrap = thumbWraps[0];
      var rectThumbWrap = firstThumbWrap.getBoundingClientRect();
      var rectReferenceDiv = referenceDiv.getBoundingClientRect();

      var xRelativeToReferenceDiv = rectThumbWrap.left - rectReferenceDiv.left;
      var yRelativeToReferenceDiv = rectThumbWrap.top - rectReferenceDiv.top;
      var imgElement = firstThumbWrap.querySelector('img');

      if (imgElement) {
        var imgWidth = imgElement.width;
        var imgHeight = imgElement.height;
        var rectImg = imgElement.getBoundingClientRect();
        var xImgRelativeToReferenceDiv = rectImg.left - rectReferenceDiv.left;
        var yImgRelativeToReferenceDiv = rectImg.top - rectReferenceDiv.top;
        setIMGWCurrentSlider(imgWidth)
        setIMGHCurrentSlider(imgHeight)
        setXCurrentSlider(xImgRelativeToReferenceDiv)
        setYCurrentSlider(yImgRelativeToReferenceDiv)
        //console.log('xcurrentSlidernext')
        //console.log(xImgRelativeToReferenceDiv)
        //console.log('ycurrentSlidernext')
        //console.log(yImgRelativeToReferenceDiv)
        //console.log('X: ' + xImgRelativeToReferenceDiv + ', Y: ' + yImgRelativeToReferenceDiv);
        //console.log('W: ' + imgWidth + ', H: ' + imgHeight);
      }


    } else {
      //console.log('No visible thumb-wrap element found.');
    }
  }

  function handleMoveLeft() {
    if (currentSlide == slides.length-1) {
      setCurrentSlider1(0)
      sliderpercent = (100 / slides.length);
      setCurrentSlider1(sliderpercent)
      setCurrentSlider(sliderpercent + '%')
    } else {
      sliderpercent = currentSlider1 + (100 / slides.length);
      setCurrentSlider1(sliderpercent)
      setCurrentSlider(sliderpercent + '%')
    }
    if (currentSlide == slides.length-1) { setCurrentSlide(0) }
    movecount = movecount + 1
    var moveableDiv = document.getElementById('moveableDiv');

    var thumbWraps = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 0"])');
    var thumbWrapshidden = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 1"])');
    var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="display: none"])');
    // var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="opacity: 0"])');
    var thumbimghidden = moveableDiv.querySelectorAll('.thumb-img-box-unique');

    thumbimghidden.forEach(function (thumbWraphidden, index) {
      //   if(index==0)
      //   thumbWraphidden.style.display = "none";
      // else
      thumbWraphidden.style.display = "block";
    });

    var firstThumbWrap = thumbWraps[0];
    var firstThumbWraphidden = thumbWrapshidden[0];
    var firstThumbimg = thumbimg[0];
    var secondThumbWrap = thumbWraps[1].cloneNode(true);
    var movetolast = secondThumbWrap;

    firstThumbimg.style.display = 'none';
    firstThumbWrap.style.opacity = 0;
    if (movecount) {
      firstThumbimg = thumbimg[1];
      firstThumbimg.style.display = 'none';
      firstThumbWrap.style.opacity = 0;
    }
    thumbWraps.forEach(function (thumbWrap, index) {
      var translation = index == 0 ? -670 : index == 1 ? -560 : index == 2 ? -450 : index == 3 ? -340 : index == 4 ? -230 : 0; // Adjust the value as needed
      thumbWrap.style.transform = 'translateX(' + translation + '%)';
       thumbWrap.style.transition = 'opacity 1s ease-in-out 0s, transform 1s ease-in-out 0s';
     // thumbWrap.style.transition = 'transform 1s ease-in-out 0s';
    });

    setTimeout(function () {
      thumbimghidden.forEach(function (thumbWraphidden, index) {
        thumbWraphidden.style.display = "block";
      });
      thumbWrapshidden.forEach(function (thumbWraphidden, index) {
        var translation = -230; // Adjust the value as needed
        thumbWraphidden.style.transform = 'translateX(' + translation + '%)';
        thumbWraphidden.style.opacity = 1;
        moveableDiv.appendChild(thumbWraphidden);
      });
    }, 1500);


    var abc = 1;

  }

  const handlepreviousButtonClick = (animationType) => {
    const nextbuttonorg = document.getElementById('nextbuttonorg');
    const nextbuttondummy = document.getElementById('nextbuttondummy');
    nextbuttonorg.style.display = 'none';
    nextbuttondummy.style.display = 'block';

    const prevbuttonorg = document.getElementById('prevbuttonorg');
    const prevbuttondummy = document.getElementById('prevbuttondummy');
    prevbuttonorg.style.display = 'none';
    prevbuttondummy.style.display = 'block';


    const newSlide = (currentSlide - 1 + slides.length) % slides.length;
    if (newSlide == 0)
      //setCurrentSlide(slides.length);
      setCurrentSlide(prevCount => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return slides.length;
      });
    else
     // setCurrentSlide(newSlide);
     setCurrentSlide(prevCount => {
      //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
      // Your logic here
      return newSlide;
    });
    setCurrentAnimation(animationType);
    getprevaxis();
    handleMoveright();
    handleprevtextchange();
    setTimeout(() => {
      const nextbuttonorg = document.getElementById('nextbuttonorg');
      const nextbuttondummy = document.getElementById('nextbuttondummy');
      nextbuttonorg.style.display = 'block';
      nextbuttondummy.style.display = 'none';

      const prevbuttonorg = document.getElementById('prevbuttonorg');
      const prevbuttondummy = document.getElementById('prevbuttondummy');
      prevbuttonorg.style.display = 'block';
      prevbuttondummy.style.display = 'none';
    }, 1500);
  };

  function handleprevtextchange() {
    const slideCaptionsall = document.querySelectorAll('.slide-caption');
    const slideCaptions = document.querySelectorAll('.slide-caption:not([style*="opacity: 0"])');
    const slideCaptionshidden = document.querySelectorAll('.slide-caption:not([style*="opacity: 1"])');
    //console.log(slideCaptionshidden)
    const firstSlideCaption = slideCaptions[0];
    if (firstSlideCaption) {
      firstSlideCaption.style.transition = 'opacity 0.8s ease-in-out 0s, transform 1s ease-in-out 0s';
      //firstSlideCaption.style.transition = 'transform 2s ease-in-out';
      firstSlideCaption.style.opacity = '0';
      firstSlideCaption.style.zIndex = '-1';
      firstSlideCaption.style.transform = 'translateY(40px)';
      // Optionally, you can remove the element after the fade-out effect
      setTimeout(() => {
        // firstSlideCaption.style.display = 'none';
      }, 1000); // Adjust the timeout based on the duration of the transition
    }

    slideCaptionshidden.forEach(function (firstSlideCaptionhidden, index) {
      firstSlideCaptionhidden.style.transform = 'translateY(0px)';
    });
    const firstelementindiv=slideCaptionsall[0];
    const slideCaptionhidden = slideCaptionshidden[slideCaptionshidden.length-1];
    if (slideCaptionhidden) {
      slideCaptionhidden.style.transition = 'opacity 1s ease-in';
      //slideCaptionhidden.style.transform = 'translateY(0px)';
      slideCaptionhidden.style.opacity = '1';
      slideCaptionhidden.style.zIndex = '1';
      // After a delay, apply the final style
      setTimeout(function () {
        var moveableDiv = document.getElementById('initialhw1');
        moveableDiv.insertBefore(slideCaptionhidden,firstelementindiv);
        slideCaptionhidden.style.transform = 'translateY(0px)';
      }, 1500);// Adjust the timeout based on the duration of the transition
    }
    settextnextSlider(textnextSlider + 1)
    if (textnextSlider == slides.length) {
      settextnextSlider(1)
    }
  }
  
  function getprevaxis() {
    var moveableDiv = document.getElementById('moveableDiv');
    var referenceDiv = document.getElementById('referenceDiv');

    var thumbWraps = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 0"])');

    if (thumbWraps.length > 0) {
      var firstThumbWrap = thumbWraps[0];
      var rectThumbWrap = firstThumbWrap.getBoundingClientRect();
      var rectReferenceDiv = referenceDiv.getBoundingClientRect();

      var xRelativeToReferenceDiv = rectThumbWrap.left - rectReferenceDiv.left;
      var yRelativeToReferenceDiv = rectThumbWrap.top - rectReferenceDiv.top;
      var imgElement = firstThumbWrap.querySelector('img');

      if (imgElement) {
        var imgWidth = imgElement.width;
        var imgHeight = imgElement.height;
        var rectImg = imgElement.getBoundingClientRect();
        var xImgRelativeToReferenceDiv = rectImg.left - rectReferenceDiv.left;
        var yImgRelativeToReferenceDiv = rectImg.top - rectReferenceDiv.top;
        setIMGWCurrentSlider(imgWidth)
        setIMGHCurrentSlider(imgHeight)
        setXCurrentSlider(xImgRelativeToReferenceDiv)
        setYCurrentSlider(yImgRelativeToReferenceDiv)
        //console.log('xcurrentSliderprev')
        //console.log(xImgRelativeToReferenceDiv)
        //console.log('ycurrentSliderprev')
        //console.log(yImgRelativeToReferenceDiv)
        //console.log('X: ' + xImgRelativeToReferenceDiv + ', Y: ' + yImgRelativeToReferenceDiv);
        //console.log('W: ' + imgWidth + ', H: ' + imgHeight);
      }


    } else {
      //console.log('No visible thumb-wrap element found.');
    }
  }

  function handleMoveright() {
    if (currentSlide == 0) {
      setCurrentSlider1(0)
      sliderpercent = (100 / 1);
      setCurrentSlider1(sliderpercent)
      setCurrentSlider(sliderpercent + '%')
    } else {
      sliderpercent = currentSlider1 - (100 / slides.length);
      setCurrentSlider1(sliderpercent)
      setCurrentSlider(sliderpercent + '%')
    }
    if (currentSlide == 1) { setCurrentSlide(0) }
    movecount = movecount + 1


    var moveableDiv = document.getElementById('moveableDiv');

    var thumbWraps = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 0"])');
    var thumbWrapshidden = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 1"])');
    var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="display: none"])');
    // var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="opacity: 0"])');
    var thumbimghidden = moveableDiv.querySelectorAll('.thumb-img-box-unique');

    // thumbimghidden.forEach(function (thumbWraphidden, index) {
      
    //   thumbWraphidden.style.display = "block";
    // });
   

    var firstThumbWrap = thumbWraps[0];
    var firstThumbWraphidden = thumbWrapshidden[0];
    var firstThumbimg = thumbimg[0];
    var secondThumbWrap = thumbWraps[1].cloneNode(true);
    var movetolast = secondThumbWrap;

    //firstThumbimg.style.display = 'none';
    //firstThumbWrap.style.opacity = 0;
    // if (movecount) {
    //   firstThumbimg = thumbimg[1];
    //   firstThumbimg.style.display = 'none';
    //   firstThumbWrap.style.opacity = 0;
    // }
   
    thumbimghidden.forEach(function (thumbWraphidden, index) {
     thumbWraphidden.style.display = "block";
    });
    thumbWrapshidden.forEach(function (thumbWraphidden, index) {
      var translation = -560; // Adjust the value as needed
      //thumbWraphidden.style.display = "block";
      thumbWraphidden.style.transition = 'opacity 1s ease-in 0s, transform 0.5s ease-in 0s';
      
      thumbWraphidden.style.transform = 'translateX(' + translation + '%)';
      thumbWraphidden.style.opacity = 1;
      //moveableDiv.insertBefore(thumbWraphidden,firstThumbWrap);
    });
   
    thumbWraps.forEach(function (thumbWrap, index) {
      // var translation = index == 0 ? -670 : index == 1 ? -560 : index == 2 ? -450 : index == 3 ? -340 : index == 4 ? -230 : 0; // Adjust the value as needed
      var translation = index===thumbWraps.length-1?-670 :index == 0 ? -450 : index == 1 ? -340 : index == 2 ? -230 : 0; // Adjust the value as needed
      thumbWrap.style.transform = 'translateX(' + translation + '%)';

      if(index==thumbWraps.length-1){
        thumbWrap.style.opacity = 0;
        thumbWrap.style.transition = 'opacity 0s ease-in-out 0s';
        thumbWrap.style.transition = 'opacity 0s ease-in 0s, transform 1.9s ease-in 0s';

      }else{
        thumbWrap.style.transition = 'opacity 1s ease-in 0s, transform 0.5s ease-in 0s';
      }
    //  thumbWrap.style.opacity = 1;
     // thumbWrap.style.transition = 'opacity 2s ease-in-out 2s, transform 2s ease-in-out';
    });

   
    
    
    

    var lastElement = thumbWraps[slides.length - 2];

    if (lastElement) {
      var imgElement = lastElement.querySelector('img');
    
      // Now you can work with imgElement and buttonElement
      if (imgElement) {
        // Do something with imgElement
       // imgElement.style.transition = 'opacity 0s ease-in-out 0s, transform 10s ease-in-out';
        imgElement.style.display='none';
      }
      lastElement.style.opacity=0;
      
      }
    setTimeout(function () {
      thumbWrapshidden.forEach(function (thumbWraphidden, index) {
       
        moveableDiv.insertBefore(thumbWraphidden,firstThumbWrap);
      });
      // thumbWrapshidden.forEach(function (thumbWraphidden, index) {
      //   var translation = -560; // Adjust the value as needed
      //   thumbWraphidden.style.transform = 'translateX(' + translation + '%)';
      //   //thumbWraphidden.style.display = "block";
      //   thumbWraphidden.style.opacity = 1;
      //   moveableDiv.insertBefore(thumbWraphidden,firstThumbWrap);
      // });
    }, 1500);


    var abc = 1;

  }
  // function handleMoveright() {
  //   if (currentSlide == slides.length) setCurrentSlide(1)
  //   movecount = movecount + 1
  //   var moveableDiv = document.getElementById('moveableDiv');

  //   var thumbWraps = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 0"])');
  //   var thumbWrapshidden = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 1"])');
  //   var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="display: none"])');
  //   var thumbimghidden = moveableDiv.querySelectorAll('.thumb-img-box-unique');

  //   thumbimghidden.forEach(function (thumbWraphidden, index) {
  //     thumbWraphidden.style.display = "block";
  //   });

  //   var firstThumbWrap = thumbWraps[0];
  //   var firstThumbWraphidden = thumbWrapshidden[0];
  //   var firstThumbimg = thumbimg[0];
  //   var secondThumbWrap = thumbWraps[1].cloneNode(true);
  //   var movetolast = secondThumbWrap;

  //   //firstThumbWrap.style.opacity = 0;
  //   //firstThumbimg.style.display = 'none';

  //   thumbWraps.forEach(function (thumbWrap, index) {
  //     var translation = index == 0 ? -670 : index == 1 ? -560 : index == 2 ? -450 : index == 3 ? -340 : index == 4 ? -230 : 0; // Adjust the value as needed
  //     var translation = index == 0 ? -450 : index == 1 ? -340 : index == 2 ? -230 : index == 3 ? -670 : index == 4 ? -230 : 0; // Adjust the value as needed
  //     if (thumbWraps.length == index)
  //       thumbWrap.style.opacity = 0;
  //     thumbWrap.style.transform = 'translateX(' + translation + '%)';
  //   });

  //   setTimeout(function () {
  //     thumbimghidden.forEach(function (thumbWraphidden, index) {
  //       thumbWraphidden.style.display = "block";
  //     });
  //     thumbWrapshidden.forEach(function (thumbWraphidden, index) {
  //       var translation = -230; // Adjust the value as needed
  //       thumbWraphidden.style.transform = 'translateX(' + translation + '%)';
  //       thumbWraphidden.style.opacity = 1;
  //       moveableDiv.appendChild(thumbWraphidden);
  //     });
  //   }, 2000);


  //   var abc = 1;

  // }



  // const handlepreviousButtonClick = (animationType) => {
  //   const newSlide = (currentSlide - 1 + slides.length) % slides.length;
  //   setCurrentSlide(newSlide);
  //   setCurrentAnimation(animationType);
  //   //console.log('newbackgroundSlideprev');
  //   //console.log(newSlide);
  //   handleMoveright();
  // };

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
  };
  let count = 0
  const getThumbnailOrder = () => {
    let order = []
    if (count == 1)
      order = [2, 3, 0, 1, 2];
    else
      order = [3, 0, 1, 2, 3];
    count = 1;
    // const order = [];
    // for (let i = 1; i <= slides.length; i++) {
    //   const index = (currentSlide + i) % slides.length;
    //   order.push(index);
    // }
    // order.push(order[0])
    return order;
  };
  

  const onetine = 0;
  let singletime = 0;
  useEffect(() => {
    // Get the container element where you want to append the divs
    var container = document.getElementById('moveableDiv');

    // Loop over the slides array and create div elements for each slide
    slides.forEach(function (slide, index) {
      // Create a new div element
      var newDiv = document.createElement('div');
      newDiv.className = 'thumb-wrap text-center';
      newDiv.style.opacity = '1';
      // Create and append a span element for the text
      var span = document.createElement('span');
      span.className = 'text-uppercase';

      if (index == slides.length - 1)
        span.textContent = slidestext[0];
      else
        span.textContent = slidestext[index + 1];

      newDiv.appendChild(span);

      // Create and append a div element for the image
      var imgDiv = document.createElement('div');
      imgDiv.className = 'thumb-img-box ms-auto me-auto overflow-hidden';

      var img = document.createElement('img');
      img.className = 'thumb-img-box-unique';
      img.src = slide;
      img.alt = 'thumbnail-' + (index + 1);
      img.title = 'thumbnail-' + (index + 1);

      imgDiv.appendChild(img);
      newDiv.appendChild(imgDiv);

      // Append the new div to the container
      // if (singletime == 1) {

        // var translation = index == slides.length-1 ? -670 :index == 0 ? -560 : index == 1 ? -450 : index == 2 ? -340 : index == 3 ? -230 : 0; // Adjust the value as needed
        var translation = index == slides.length-1 ? -670 :index == 0 ? -560 : index == 1 ? -450 : index == 2 ? -340 : index == 3 ? -230 : 0; // Adjust the value as needed

        // Apply the fade-out and translation transformations
        

        if(index == slides.length-1){
          newDiv.style.transform = 'translateX(' + translation + '%)';
          newDiv.style.opacity=0
          container.appendChild(newDiv);
          
        }else{
          newDiv.style.transform = 'translateX(' + translation + '%)';
        container.appendChild(newDiv);
        }

        sliderpercent = currentSlider1 + (100 / slides.length);
        setCurrentSlider1(sliderpercent)
        setCurrentSlider(sliderpercent + '%')

        var moveableDiv = document.getElementById('moveableDiv');
        var referenceDiv = document.getElementById('referenceDiv');

        var thumbWraps = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 0"])');
        var thumbWrapshidden = moveableDiv.querySelectorAll('.thumb-wrap:not([style*="opacity: 1"]) img');
        thumbWrapshidden.forEach(function (imgElement) {
          imgElement.style.display = 'none';
        });
        if (thumbWraps.length > 0) {
          var firstThumbWrap = thumbWraps[0];
          var rectThumbWrap = firstThumbWrap.getBoundingClientRect();
          var rectReferenceDiv = referenceDiv.getBoundingClientRect();

          var xRelativeToReferenceDiv = rectThumbWrap.left - rectReferenceDiv.left;
          var yRelativeToReferenceDiv = rectThumbWrap.top - rectReferenceDiv.top;
          var imgElement = firstThumbWrap.querySelector('img');

          if (imgElement) {
            var imgWidth = imgElement.width;
            var imgHeight = imgElement.height;
          }
          setIMGWCurrentSlider(imgWidth)
          setIMGHCurrentSlider(imgHeight)
          setXCurrentSlider(xRelativeToReferenceDiv)
          setYCurrentSlider(yRelativeToReferenceDiv)
          //console.log('xcurrentSlider')
//console.log(xcurrentSlider)
//console.log('ycurrentSlider')
//console.log(ycurrentSlider)
          //console.log('X: ' + xRelativeToReferenceDiv + ', Y: ' + yRelativeToReferenceDiv);
          //console.log('W: ' + imgWidth + ', H: ' + imgHeight);
        } else {
          //console.log('No visible thumb-wrap element found.');
        }

      // }
    });
    singletime = 1;
  }, [onetine]);

// //console.log('xcurrentSlider')
// //console.log(xcurrentSlider)
// //console.log('ycurrentSlider')
// //console.log(ycurrentSlider)
  console.log('currentSlide')
  console.log(currentSlide)
  // //console.log('imgwcurrentSlider')
  // //console.log(imgwcurrentSlider)
  // //console.log('imghcurrentSlider')
  // //console.log(imghcurrentSlider)
  // //console.log('textnextSlider')
  // //console.log(textnextSlider)
  // //console.log('slides.length')
  // //console.log(slides.length)


  return (<>
    <div className="hotels-resorts-slides position-relative mt-lg-4 mt-3 d-md-block d-none">
      <div className="slide-demo-box w-100" style={{
        // backgroundImage: `url(${slides[currentSlide - 2 == 0 ? slides.length - 1 : currentSlide - 2 == -1 ? slides.length - 2 : currentSlide - 3]})`,
        // backgroundImage: `url(${slides[currentAnimation === 'animation1'?currentSlide - 2 == 0 ? slides.length - 1 : currentSlide - 2 == -1 ? slides.length - 2 : currentSlide - 3:1]})`,
        backgroundImage: `url(${slides[currentAnimation === 'animation1'|| currentAnimation==="initial"?currentSlide == 0||currentSlide ==1 ? slides.length-1 : currentSlide-2:currentSlide===0?slides.length-1:currentSlide-1]})`,

        // backgroundImage: `url(${slides[currentSlide-1]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', // Ensure the background doesn't repeat
      }}>
      </div>
      <div id='initialhw1'
        //key={currentSlide}
        className="img-container position-absolute start-0 top-0 w-100 h-100  initialhw"
      //initial={{ opacity: 1, x: 0, y: 0, width: '118px', height: '135px' }}
      //animate={{ opacity: 1, x: 0, y: 0, scale: 1, width: '100%', height: '100%' }}
      //transition={{ duration: 100, ease: "easeInOut" }}
      >
        {/* <div className='slide-caption text-uppercase text-center text-white position-absolute z-1 start-0 top-0'>
          <span className=''>Atmosphere</span>
          <h1 className='my-3 georgia pb-4 mb-xxl-4'>KANIFUSHI</h1>
          <small>MALDIVES</small>
          <div className='slide-action'>
            <Link to='' className='text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center'>VIEW <FontAwesomeIcon icon={faArrowRightLong} className='ms-3 icon-font' /></Link>
          </div>
        </div> */}
        {dataArray.map((item, index) => (
          <div key={index} className={`slide-caption text-uppercase text-center text-white position-absolute  start-0 top-0 `} style={{"zIndex":index===0?1:-1}} >
            
            <h1 className='mt-3 mb-0 georgia'>{item.title}</h1>
            <span className='slide-small-text position-relative pb-3 mb-3 d-flex w-100 justify-content-center'>{item.heading}</span>
            <small>{item.desc}</small>
            <div className='slide-action d-flex justify-content-center'>
              <Link target={item.linkTarget} to={item.link} className='text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center'>
                {item.linkName} <FontAwesomeIcon icon={faArrowRightLong} className='ms-3 icon-font' />
              </Link>
            </div>
          </div>
        ))}
        <AnimatePresence >
          <div id="referenceDiv"></div>
          <motion.div 
            key={currentSlide}
            className="img-container-overlay d-flex align-items-end position-relative overflow-hidden "
            //initial={{ opacity: 1, x: 1035, y: 691, width: '118px', height: '135px' }}
            initial={
              currentAnimation === 'animation1'
                // ? { opacity: 1, x: 1074, y: 692, width: '118px', height: '135px' }XCurrentSlider
                ? { opacity: 1, x: xcurrentSlider, y: ycurrentSlider, width: imgwcurrentSlider, height: imghcurrentSlider }
                : currentAnimation === 'animation2'
                  ? { opacity: 1, x: 0, y: 0, scale: 1, width: '100%', height: '100%' }
                  : {} // Add more conditions for additional animations
            }
            // animate={{ opacity: 1, x: 0, y: 0, scale: 1, width: '100%', height: '100%' }}
            animate={
              currentAnimation === 'animation1'
                ? { opacity: 1, x: 0, y: 0, scale: 1, width: '100%', height: '100%' }
                : currentAnimation === 'animation2'
                  // ? { opacity: 1, x: 1074, y: 692, width: '118px', height: '135px', zIndex: 999 }
                  ? { opacity: 1, x: xcurrentSlider, y: ycurrentSlider, width: imgwcurrentSlider, height: imghcurrentSlider, zIndex: 999 }
                  : {} // Add more conditions for additional animations
            }
            transition={{ duration: 0.5, ease: "easeInOut" }}
          // style={{
          //   backgroundImage: `url(${slides[currentSlide-1]})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundRepeat: 'no-repeat', // Ensure the background doesn't repeat
          // }}
          >
            <img
              className="w-100 "
              src={slides[currentAnimation === 'animation1' || currentAnimation==="initial"?currentSlide == 0 ? slides.length -1 : currentSlide - 1:currentSlide===slides.length?0:currentSlide]}
              //src={slides[currentSlide]}
              alt={`hotel-resort-slide-${currentSlide + 1}`}
              title={`hotel-resort-slide-${currentSlide + 1}`}
            />
          </motion.div>
        </AnimatePresence>
        {/* <AnimatePresence >
          <motion.div
          key={currentSlide}
            className="img-container-overlay position-relative overflow-hidden"
            initial={{ opacity: 1, x: 0, y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0, scale: 1, width: '100%', height: '100%' }}
            transition={{ duration: 2, ease: "easeInOut" }}
            >
            <img
              className="w-100 h-100"
              
              src={slides[currentSlide-1]}
              alt={`hotel-resort-slide-${currentSlide + 1}`}
              title={`hotel-resort-slide-${currentSlide + 1}`}
            />
          </motion.div>
        </AnimatePresence> */}
      </div>


      <div className="slider-bottom-actions d-flex justify-content-between">
        <div className="slide-wrap d-flex align-items-center mt-xl-5 mt-4">
          <div className="left-control-btns d-flex">
            <div id="prevbuttonorg"  className="arrow-right" onClick={() => handlepreviousButtonClick('animation2')}  >
              <span id="previousbutton" className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center"><img src={prevArrow} alt="Next" title="Next" /></span>
            </div>
            <div id="prevbuttondummy" className="arrow-right"  style={{ 'display': 'none' }}>
              <span id="previousbutton" className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center"><img src={prevArrow} alt="Next" title="Next" /></span>
            </div>
            {/* {(currentSlide<=slides.length-1) && currentSlide!=0? */}
            <div id="nextbuttonorg" className="arrow-right" onClick={() => handlenextButtonClick('animation1')}>
              <button id="nextbutton" className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center ms-2 ms-lg-3"><img src={nextArrow} alt="Next" title="Next" /></button>
            </div>
            <div className="arrow-right" id="nextbuttondummy" style={{ 'display': 'none' }} >
              <button className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center ms-2 ms-lg-3"><img src={nextArrow} alt="Next" title="Next" /></button>
            </div>
            {/* :""} */}
          </div>
          <div className="right-slide-progress-bar d-flex align-items-center">
            <div className="line-slide-status position-relative">
              <span className="linebar-slide"></span>
              <span className="active-slide-bar position-absolute start-0" style={{ width: currentSlider }}></span>
            </div>
            <div className="slide-number text-end">
              <span>{currentSlide+1}</span>
            </div>
          </div>
        </div>

        <div id="moveableDiv" className="bottom-right-images ms-auto d-flex align-items-center justify-content-start">
          {/* <AnimatePresence>
                                                                            {getThumbnailOrder().map(
                                                                              (index) =>
                                                                                index !== currentSlide && 
                                                                                (
                                                                                  <motion.div
                                                                            key={index}
                                                                            className="thumb-wrap text-center"
                                                                            initial={{ opacity: 0, x: 0 }}
                                                                            animate={{ opacity: 1, x: -100 }}
                                                                            exit={{ opacity: 0, x: '-100%' }}
                                                                            transition={{ duration: 20, ease: 'easeInOut' }}
                                                                            staggerChildren={20} 
                                                                          >
                                                                                    <span className="text-uppercase">{index}</span>
                                                                                    <div className="thumb-img-box overflow-hidden">
                                                                                      <img
                                                                                        src={slides[index]}
                                                                                        alt={`thumbnail-${index + 1}`}
                                                                                        title={`thumbnail-${index + 1}`}
                                                                                      />
                                                                                    </div>
                                                                </motion.div>
                                                                                )
                                                                            )}
                                                                          </AnimatePresence> */}
          {/* <AnimatePresence wait>
                                                                      {getThumbnailOrder().map((index) => (
                                                                        index !== currentSlide && (
                                                                          <motion.div
                                                                            key={index}
                                                                            className="thumb-wrap text-center"
                                                                            initial={{ opacity: 0, x: 10 }}
                                                                            animate={{ opacity: 1, x: 0 }}
                                                                            exit={{ opacity: 0, x: -100 }}
                                                                            transition={{ duration: 1, ease: 'easeInOut' }}
                                                                            staggerChildren={0.1}
                                                                          >
                                                                            <span className="text-uppercase">{index}</span>
                                                                            <motion.div
                                                                              className="thumb-img-box overflow-hidden"
                                                                              initial={{ opacity: 0, scale: 0.8 }}
                                                                              animate={{ opacity: 1, scale: 1 }}
                                                                              exit={{ opacity: 0, scale: 0.8 }}
                                                                              transition={{ duration: 1, ease: 'easeInOut' }}
                                                                            >
                                                                              <img
                                                                                src={slides[index]}
                                                                                alt={`thumbnail-${index + 1}`}
                                                                                title={`thumbnail-${index + 1}`}
                                                                              />
                                                                            </motion.div>
                                                                          </motion.div>
                                                                        )
                                                                      ))}
                                                                    </AnimatePresence> */}

          {/* {getThumbnailOrder().map(
            (index) =>
            //index !== currentSlide && 
            (
              <div
                className="thumb-wrap text-center"
              >
                <span className="text-uppercase">{index}</span>
                <div className="thumb-img-box overflow-hidden">
                  <img
                    src={slides[index]}
                    alt={`thumbnail-${index + 1}`}
                    title={`thumbnail-${index + 1}`}
                  />
                </div>
              </div>
            )
          )} */}

        </div>
      </div>
    </div>

    <div className="brands-slide d-block d-md-none">
      <div className="mobile-slides-hotel-resort mt-4">
        <HotelsResortsMobileSlider gallerySettings={gallerySettings} images={dataArray} />
      </div>
    </div>

  </>

  );
};

export default HotelsResortsSlides;
