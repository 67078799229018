import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/offers-cover.jpg'
import OffersTabs from './OffersTabs';
import { Link } from 'react-router-dom';

function Offers() {
  return (
    <>
    <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>OFFERS</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Our Offers</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>
        <section>
            <div className='sub-brands-tabs offers-tabs section-padding'>
                <OffersTabs />
            </div>
        </section>
    </>
  )
}

export default Offers