import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/varu-lowest-price-assurance-cover.jpg'
import largeOffer from '.././assets/images/varu-lowest-price-assurance.jpg'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import Modals from '../components/Modals';

function VaruBookDIrect() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>OFFERS</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/offers'>Offers</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Book Direct – Lowest Price Assurance</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='offer-details section-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='small-heading text-center mb-4 text-uppercase'>Varu - MALDIVES</div>
                            <div class="dashed-heading position-relative"><h2 class="georgia text-uppercase text-center mb-0"><span className='px-4'>All-Inclusive Holiday- VARU PLAN<sup>™</sup></span></h2></div>
                            <h2 class="georgia text-uppercase text-center mb-0 mt-4 fw-bold">Book Direct – Lowest Price Assurance</h2>
                        </div>
                        <div className='row mt-lg-5 mt-4'>
                            <div className='col-12'>
                                <div className='large-img-offer'>
                                    <img className='w-100' src={largeOffer} alt='Offer' title='Offer' />
                                </div>
                                <div className='offer-para mt-4 pt-2'>
                                    <p>Experience the ultimate luxury getaway in the Maldives by booking directly with us!</p>
                                    <p>As our valued guest, you will receive a generous 20% discount on your stay. Don’t miss out on this opportunity to save and indulge in the finest amenities and service. We look forward to welcoming you to our resort and creating lasting memories for you to treasure.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-5 mt-4'>
                            <div className='col-lg-6 pe-lg-4'>
                                <div class="dashed-heading position-relative"><h4 class="georgia text-uppercase mb-0"><span className='pe-3'>HIGHLIGHTS</span></h4></div>
                                <div className='offer-points mt-lg-4 mt-3'>
                                    <ul className='m-0'>
                                        <li><span>20% OFF Booking</span></li>
                                        <li><span>All-Inclusive Holiday- VARU PLAN<sup>™</sup></span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6 ps-lg-4 mt-lg-0 mt-md-5 mt-4'>
                                <div class="dashed-heading position-relative"><h4 class="georgia text-uppercase mb-0"><span className='pe-3'>TERMS & CONDITIONS</span></h4></div>
                                <div className='offer-points mt-lg-4 mt-3'>
                                    <ul className='m-0'>
                                        <li><span>Minimum 4 nights stay</span></li>
                                        <li><span>For stays until 31st October 2024</span></li>
                                        <li><span>This offer cannot be combined with any other special offer</span></li>
                                        <li><span>For all room categories</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 mt-xl-5 mt-4'>
                                <div className='offer-book-action'>
                                <Button className='btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary' onClick={handleShow}>Book Now</Button>
                                <Modals show={show} handleClose={handleClose}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default VaruBookDIrect