import React from 'react'
import AtmosphereVideo from '../assets/video/atmosphere-kanifushi.mp4'
import AtmosphereVideoCover from '../assets/images/atmosphere-living-banner.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

function HomeVideo() {
  return (
    <>
        <section>
            <div className='atmosphere-hero-video position-relative'>
            <video
              id="BgVideo"
              className='w-100 d-flex'
              poster={AtmosphereVideoCover}
               preload="auto"
               loop
               muted
               autoPlay
               playsInline
            >
              <source
                preload="auto"
                src={AtmosphereVideo}
                type="video/mp4"
              />
            </video>
            <div className='scroll-down d-flex justify-content-center position-absolute start-0 end-0 z-1 flex-column text-center'>
              <a href='#BookNow' className='text-white'>
                <span className='arrow1'><FontAwesomeIcon icon={faChevronDown} className='ms-2' /></span>
                <span className='arrow2'><FontAwesomeIcon icon={faChevronDown} className='ms-2' /></span>
                <span className='arrow3'><FontAwesomeIcon icon={faChevronDown} className='ms-2' /></span>
              </a>              
            </div>
            </div>
        </section>
    </>
  )
}

export default HomeVideo