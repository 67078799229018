import React from 'react'
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import TabItem from './TabItem';
import coverOne from '../assets/images/atmosphere-tab-cover.png'
import coverTwo from '../assets/images/by-atmosphere-cover.png'
import coverThree from '../assets/images/atmosphere-experience-cover.png'
import coverFour from '../assets/images/signature-atmosphere.png'
import coverFive from '../assets/images/nature-by-atmosphere-cover.png'
import coverSix from '../assets/images/haritage-by-atmosphere-cover.png'
import coverSeven from '../assets/images/urban-atmosphere-cover.png'
import TablogoOne from '../assets/images/tabone-logo.svg'
import TablogoTwo from '../assets/images/tabtwo-logo.svg'
import TablogoThree from '../assets/images/tabthree-logo.svg'
import TablogoFour from '../assets/images/tabfour-logo.svg'
import TablogoFive from '../assets/images/element-of-nature-by-atmosphere.svg'
import TablogoSix from '../assets/images/heritage-by-atmosphere.svg'
import TablogoSeven from '../assets/images/an-urban-atmosphere.svg'
import hotelKanifushi from '../assets/images/kanifushi-thumb.jpg'
import hotelVaru from '../assets/images/varu-thumb.jpg'
import resortImgOne from '../assets/images/marina-gate.jpg'
import resortImgTwo from '../assets/images/resort-two.jpg'
import resortImgThree from '../assets/images/resort-three.jpg'
import resortImgFour from '../assets/images/resort-four.jpg'
import signatureImgOne from '../assets/images/signature-one.jpg'
import signatureImgTwo from '../assets/images/signature-two.jpg'
import signatureImgThree from '../assets/images/signature-three.jpg'
import TabImgOne from '../assets/images/tabone-img.jpg'
import TabImgTwo from '../assets/images/tabtwo-img.jpg'
import TabImgThree from '../assets/images/tabthree-img.jpg'
import TabImgFour from '../assets/images/tabfour-img.jpg'
import TabImgSix from '../assets/images/tabsix-img.jpg'
import TabImgSeven from '../assets/images/tabseven-img.jpg'
import TabImgEight from '../assets/images/tabeight-img.jpg'
import discoverImgOne from '../assets/images/discover-one.jpg'
import discoverImgTwo from '../assets/images/discover-two.jpg'
import discoverImgThree from '../assets/images/discover-three.jpg'
import discoverImgFour from '../assets/images/discover-four.jpg'
import discoverImgFive from '../assets/images/discover-five.jpg'
import discoverImgSix from '../assets/images/discover-six.jpg'
import discoverImgSeven from '../assets/images/discover-seven.jpg'
import discoverImgEight from '../assets/images/discover-eight.jpg'
import discoverImgNine from '../assets/images/discover-nine.jpg'
import discoverImgTen from '../assets/images/discover-ten.jpg'
import discoverImgEleven from '../assets/images/discover-eleven.jpg'
import discoverImgTwelve from '../assets/images/discover-twelve.jpg'
import discoverImgThrteen from '../assets/images/discover-thrteen.jpg'
import discoverImgFourteen from '../assets/images/discover-fourteen.jpg'
import discoverImgFifteen from '../assets/images/discover-fifteen.jpg'
import discoverImgSixteen from '../assets/images/discover-sixteen.jpg'
import discoverImgSeventeen from '../assets/images/discover-seventeen.jpg'
import discoverImgEighteen from '../assets/images/discover-eighteen.jpg'
import discoverImgNinteen from '../assets/images/discover-ninteen.jpg'
import discoverImgTwenty from '../assets/images/discover-twenty.jpg'
import discoverImgTwentyOne from '../assets/images/discover-twenty-one.jpg'
import discoverImgTwentyTwo from '../assets/images/discover-twenty-two.jpg'
import discoverImgTwentyThree from '../assets/images/discover-twenty-three.jpg'
import discoverImgTwentyFour from '../assets/images/discover-twenty-four.jpg'
import discoverImgTwentyFive from '../assets/images/discover-twenty-five.jpg'
import discoverImgTwentySix from '../assets/images/discover-twenty-six.jpg'
import discoverImgTwentySeven from '../assets/images/discover-twenty-seven.jpg'
import discoverImgTwentyEight from '../assets/images/discover-twenty-eight.jpg'
import hotelSinha from '../assets/images/sinharaja-resort.jpg'
import hotelHariMahal from '../assets/images/hotel-hari-mahal.jpg'
import hotelBarsha from '../assets/images/barsha.jpg'


import discoverIconOne from '../assets/images/spoon-fork.svg'
    const SubBrandTabs = () => {       
        // define state with initial value to let the tabs start with that value
        const [activeTab, setActiveTab] = React.useState(0);      
        // define a onClick function to bind the value on tab click
        const onTabClick = (e, index) => {
          console.log(e);
          setActiveTab(index);
        };
        // const tabsArray = [...Array(5).keys()];
        const tabsArray = [            
          {coverId:0,coverImage:coverOne,tabLogo: TablogoOne,tabPara:"Atmosphere invites you to a world where we reimagine classic luxury, with each resort thoughtfully crafted to celebrate its individuality and the charm of its surroundings. Indulge in an elevated experience tailored to your desires.",hotelImage:hotelKanifushi,hotelLink:"dfsdsf",hotelName:"Kanifushi",hotelLocation:"Maldives", brandtabs:'brandone',tabResorts:[], name: 'Atmosphere', tabCircleImage: TabImgOne, brandSubTitle: "Crafting unique narratives",visitResort: 'https://atmosphere-kanifushi.com/', visitResortName: 'Visit Resort',
          linksData:[{id: 1, text: 'Book Now',}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgOne,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Bedroom', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgTwo,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Dining', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgThree,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Spa Services', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgFour,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Excursions & Adventures ', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]        
        },

          {coverId:1,coverImage:coverTwo,tabLogo: TablogoTwo,tabPara:"By Atmosphere offers the experience of charming getaways that feel like a warm embrace. Here, heartfelt moments unfold effortlessly and beautifully with our warm and genuine hospitality, where each guest is family to us.",hotelImage:hotelVaru,hotelLink:"dfsdsf",hotelName:"VARU",hotelLocation:"Maldives", brandtabs:'brandtwo',tabResorts:[], name: 'by Atmosphere', tabCircleImage: TabImgTwo, brandSubTitle: "Capturing heartfelt moments", visitResort: 'https://varu-atmosphere.com/', visitResortName: 'Visit Resort',
          linksData:[{id: 1, text: 'Book Now'}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgFive,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Beach Villas', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgSix,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Floating Breakfast', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgSeven,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Renewal of Vows', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgEight,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Dive & Sail', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]
        },

          {coverId:2,coverImage:coverThree,tabLogo: TablogoThree,tabPara:"An Atmosphere Experience is a locally inspired tribute, creating a rich and harmonious blend of luxury and authenticity. The immersive atmosphere extends beyond design, evoking a sense of connection with your natural surroundings and cultural elements.", hotelImage:hotelVaru,hotelLink:"dfsdsf",hotelName:"VARU",brandSubTitle: "Whispers of landscapes", hotelLocation:"Maldives", brandtabs:'brandthree', tabCircleImage: TabImgThree, name: 'an Atmosphere Experience', tabResorts:[
            {resortImg:resortImgOne,resortName:'MARINA GATE',resortLocation:"Dubai"},
            {resortImg:resortImgTwo,resortName:'RESORT II',resortLocation:"Dubai"},
            {resortImg:resortImgThree,resortName:'RESORT III',resortLocation:"Dubai"},
            {resortImg:resortImgFour,resortName:'RESORT IV',resortLocation:"Dubai"}
          ],
          linksData:[{id: 1, text: 'Enquire', url: '/contact-us'}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgNine,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgTen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgEleven,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgTwelve,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]
        },

          {coverId:3,coverImage:coverFour,tabLogo: TablogoFour,tabPara:"A Signature Atmosphere enchants you with the grandeur and elegance of iconic properties. The experience is exceptional and timeless, enduring and distinctive. The stay is nothing short of enriching as we take you on a journey through time and history.", brandSubTitle:"A tale as old as time", hotelImage:hotelVaru,hotelLink:"dfsdsf",hotelName:"VARU",hotelLocation:"Maldives", brandtabs:'brandfour', name: 'a signature atmosphere', tabCircleImage: TabImgFour,tabResorts:[
            {resortImg:signatureImgOne,resortName:'LALGARGH PALACE',resortLocation:"Rajsthan"},
            {resortImg:signatureImgTwo,resortName:'RESORT II',resortLocation:"Dubai"},
            {resortImg:signatureImgThree,resortName:'RESORT III',resortLocation:"Dubai"}
          ],
          linksData:[{id: 1, text: 'Enquire', url: '/contact-us'}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgThrteen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgFourteen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgFifteen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgSixteen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum ', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]
        },

          {coverId:4,coverImage:coverFive,tabLogo: TablogoFive,tabPara:"Elements of Nature are serene wellness retreats designed to offer a tranquil escape and rejuvenation. It's an invitation to unwind, reconnect, and immerse yourself in a holistic experience where each moment is composed of the soothing sounds of nature.", brandSubTitle:"A symphony of serenity", hotelImage:hotelSinha,hotelLink:"dfsdsf",hotelName:"SINHARAJA RETREAT",hotelLocation:"TANGALLE", brandtabs:'brandfive',tabResorts:[], tabCircleImage: TabImgSix, name:'elements of nature by atmosphere',visitResortName: 'Visit Resort',
          linksData:[{id: 1, text: 'Enquire', url: '/contact-us'}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgSeventeen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgEighteen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgNinteen,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgTwenty,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]
        },


          {coverId:5,coverImage:coverSix,tabLogo: TablogoSix,tabPara:"Heritage by Atmosphere is a meticulous curation of historical gems that serve as a gateway to the grandeur of the past. The ambience is enchanting and ethereal, the past still faintly present, allowing an immersion in the unique narrative of these properties.",hotelImage:hotelHariMahal,hotelLink:"dfsdsf",hotelName:"HARI MAHAL",hotelLocation:"JODHPUR", brandtabs:'brandsix',tabResorts:[], tabCircleImage: TabImgSeven, name:'Heritage by Atmosphere', brandSubTitle: "Dreamlike echoes of history",visitResortName: 'Visit Resort',
          linksData:[{id: 1, text: 'Enquire', url: '/contact-us'}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgTwentyOne,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgTwentyTwo,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgTwentyThree,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgTwentyFour,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]
        },

          {coverId:6,coverImage:coverSeven,tabLogo: TablogoSeven,tabPara:"An Urban Atmosphere brings authentic hotels and resorts to the heart of the city, providing a vibrant backdrop for your adventures. It is an urban exploration elevated with authentic and locally inspired experiences.", brandSubTitle:"Where urban stories come alive",hotelImage:hotelBarsha,hotelLink:"dfsdsf",hotelName:"BARSHA 55",hotelLocation:"DUBAI", brandtabs:'brandseven',tabResorts:[], tabCircleImage: TabImgEight, name:'an urban Atmosphere',visitResortName: 'Visit Resort',
          linksData:[{id: 1, text: 'Enquire', url: '/contact-us'}],
          tabDiscoverLeftSideCards:[
            {discoverImage:discoverImgTwentyFive,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            } ,
            {discoverImage:discoverImgTwentySix,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            },
            {discoverImage:discoverImgTwentySeven,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }    
          ],
          tabDiscoverRightSideCards:[
            {discoverImage:discoverImgTwentyEight,              
              itemsdetails:[{discoverIcon:discoverIconOne,discoverTitle: 'Lorem ipsum', discoverCardPara: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}] 
            }   
          ]
        },
  ]
;
  return (
    <>
        <Tabs activeTab={activeTab} onTabClick={onTabClick} tabsArray={tabsArray}>
        {/* generating an array to loop through it  */}

        {tabsArray.map((item) => (
          <Tab 
          key={item.coverId} className={`item ${activeTab === item.coverId ? 'active text-uppercase d-flex align-items-center' : 'text-uppercase d-flex align-items-center'}`}>
            <span className='tab-feature-img d-flex rounded-circle overflow-hidden'><img src={item.tabCircleImage} alt='' title='' /></span><span className='tab-name'>{item.name}</span>
          </Tab>
        ))}
      </Tabs>

{tabsArray.map((screen)=>(
    <TabScreen
    key={screen.coverId}
    activeTab={activeTab}
    index={screen.coverId}

    // You can add animation with adding a custom class
  >
    <TabItem coverImage={screen.coverImage} tabLogo={screen.tabLogo} brandSubTitle={screen.brandSubTitle} brandtabs={screen.brandtabs} tabPara={screen.tabPara} hotelImage={screen.hotelImage} hotelLink={screen.hotelLink} hotelName={screen.hotelName} hotelLocation={screen.hotelLocation} tabResorts={screen.tabResorts} discoverIcon={screen.discoverIcon} tabDiscoverLeftSideCards={screen.tabDiscoverLeftSideCards} tabDiscoverRightSideCards={screen.tabDiscoverRightSideCards} linksData={screen.linksData} visitResort={screen.visitResort} visitResortName={screen.visitResortName}/>
  </TabScreen>
    
    
))}
      {/* {tabsArray.map((item) => (
        <TabScreen
          key={item}
          activeTab={activeTab}
          index={item}
          // You can add animation with adding a custom class
          className="some-animation-class"
        >
          
        </TabScreen>
      ))} */}
    
    {/* <TabItem/> */}

    </>
  )
}

export default SubBrandTabs