import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import menuImg from '../assets/images/menu.svg'
import logo from '../assets/images/atmosphere-living-logo.png'
import logoWithCircle from '../assets/images/atmosphere-logo-with-circle.png'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Modals from './Modals';

const Header = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [menuItem,setMenuItem] = useState(false);
    const [expanded, setExpanded] = useState(false);
    console.log(menuItem);
  return (
    <>
        <header className='start-0 top-0 end-0 z-3'>
            <div className='header-wrap position-relative'>
                <div className='container-fluid'>
                    <div className='row position-relative'>
                        <button className='menu-btn border-0 bg-transparent position-absolute z-2 d-none'>
                            <img src={menuImg} alt='Menu' title='Menu' />
                        </button>
                        {/* /col */}
                        <div className='col d-lg-none'>
                            <div className='logo-device-col'>
                                <div className='device-logo position-relative'>
                                    <Link to='/'><img src={logo} title='Atmosphere Living' alt='Atmosphere Living' /></Link>
                                </div> 
                                <div className='mobile-book-now d-lg-none'>
                                    <Button className='btn outline-success text-white book-btn text-uppercase px-4 py-2' onClick={handleShow}>Book Now</Button>                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='atmosphere-living-nav'>
                            <Navbar expanded={expanded} expand="lg" className="navbar-nav smooth">
                                <Navbar.Toggle className="shadow-none"aria-controls="basic-navbar-nav" onClick={()=>setExpanded(prev=>!prev)} />
                                <Navbar.Collapse id="basic-navbar-nav">
                                <Nav as='ul' className="me-auto d-flex align-items-center text-uppercase">
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to='/'><span>Home</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)}   as='li'><NavLink className='text-white nav-link' to="/our-brands-page"><span>OUR SUB BRANDS</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/our-story"><span>OUR STORY</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li' className='logo-item'>
                                        <NavLink className='text-white position-relative d-flex' to="/">
                                            <img className='position-absolute start-0 logo-fixed' src={logo} title='Atmosphere Living' alt='Atmosphere Living' />
                                            <img className='position-absolute start-0  logo-sticky' src={logoWithCircle} title='Atmosphere Living' alt='Atmosphere Living' />
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/sustainability"><span>SUSTAINABILITY</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link'  to='/offers'><span>OFFERS</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} className='booking-nav-item d-none d-lg-flex' as='li'>
                                    <Button className='btn outline-success book-btn text-uppercase px-4 py-2' onClick={handleShow}>Book Now</Button></Nav.Item>
                                    <Modals show={show} handleClose={handleClose}/>
                                </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                            </div>
                            {/* /atmosphere-living-nav */}
                        </div>
                        {/* /col */}
                    </div>
                    {/* /row */}
                </div>
                {/* container-fluid */}
            </div>
        </header>
    </>
  )
}

export default Header