import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/offers-cover.jpg'
import pressDetail from '../assets/images/press-two.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ThumbPressItem from './ThumbPressItem';
import thumbOne from '../assets/images/thumb-one.jpg'
import thumbThree from '../assets/images/thumb-three.jpg'
import thumbFour from '../assets/images/thumb-four.jpg'
import { Link } from 'react-router-dom';

function KanifushiEnjoyTheBestOfTheMaldives() {
    const presstextone = ["<p>Escape to a secluded island getaway amidst azure Indian Ocean waters and enjoy your dream Maldives vacation with your loved ones. Situated in the Lhaviyani Atoll, an exhilarating 35-minute seaplane ride from the International Airport in Malé, Atmosphere Kanifushi is a stunning private sanctuary. Approximately 2 km in length and 90 meters wide, the island is blanketed by dense tropical vegetation and blessed with vibrant, natural coral reefs.</p><p>Atmosphere Kanifushi serenades you with white, sandy beaches, swaying palm trees, and lush tropical gardens. Time stops as sunshine sparkles on the turquoise lagoon. Magical realms await to be explored beneath crystal clear waters. You can choose to fill your days with exhilarating adventures or indulge in blissful relaxation</p>"]
    
    const presstexttwo = ["<p>A carefree, five-star resort experience awaits you with the resort’s luxurious all-inclusive holiday plan, Kanifushi Plan™. Atmosphere Kanifushi offers a premium all-inclusive meal plan enhancing the typical 5-star Maldivian resort experience by going that extra mile, offering a combination of sumptuous buffets with delectable international cuisine, fine dining experiences, and theme night dining options.</p><p>The exquisite dining experience is clubbed with an array of activities, excursions, and adventures ranging from sunset fishing to snorkeling and non-motorised water sports – ensuring a fun-filled and hassle-free tropical beach holiday experience.</p>"]
    
    const presstextthree = ["<p>Travellers wanting to avail of an absolutely private experience can opt for the Kanifushi Grand Pool Villas with sweeping oceanfront views. The stunning lap pool with a fabulous poolside setup invites you to lounge, laze and soak in the refreshing tropical vibes – ideal for couples and young families</p><p>Sunset Beach Villas are a secluded sanctuary at the northern-most tip of the island, where you can soak in the pleasures of island living in your private garden – with lush tropical shrubs, soft sand beneath your feet, and an inviting 30m² private pool. Gaze at the vast azure lagoon of Kanifushi Island, from the comfort of your bed or from the intimate seating area.</p><p>For larger families looking for extra privacy – Kanifushi Residence is the deal. A dream family hideaway amidst a pristine beach and towering palms. Complete with a living and dining room with a pantry, an entertainment room, and two bedrooms – a master bedroom, a kids bedroom with twin beds, and an attached nanny’s bedroom. A spacious wooden deck with table seating and a Maldivian swing create the perfect chilled-out spot for family moments.</p><p>The two-bedroom Sunset Family Beach Villas at Kanifushi are another brilliant option for big families. With expansive living spaces to make for fun family time while also providing a quiet corner for some solitude, the two interconnected rooms feature a combination of indoor and outdoor spaces making it the best private villa in Maldives. With easy access to the kid’s club, sports complex, and the main restaurant, these villas are perfect for experiencing all the activities that the resort offers.</p><p>Offering a range of villas to suit every taste and requirement – the Atmosphere Kanifushi has been named the Maldives’ Leading Family Resort 2022 for three consecutive years at the World Travel Awards.</p>"]
    
    const presstextfour = ["<p>While there are various spots on Kanifushi island to witness the magical sunrise and sunsets, there are some unique experiences that take this enchanting phenomenon to a new level.</p><p>During the day, the Sunset Pool Bar sets the perfect tone for beachside relaxation with spectacular views of the shimmering lagoon. The adult-only pool at the bar is the perfect spot for enjoying romantic sundowners with your loved ones. Relish light meals from the à la carte lunch menu or experience Moroccan magic at Marrakech with a selection of flavoured shisha and Moroccan tea and coffee.</p><p>Besides the sunset pool bar, the sunset beach villas offer bookend sun-soaked days with a stunning lagoon view in the morning and the famed, dazzling Maldivian sunsets in the evening – all from the comfort of your private villa.</p><p>You can also enjoy unforgettable moments and watch the splendors of the sun during your private sunset cruise. Witness the distinctive hues of blue and turquoise transform into soft red, pink, purple, and deep orange as you float across the sea.</p>"]
    
    const presstextfive = ["<p>While you may go to the Maldives for its exotic tropical views, Atmosphere Kanifushi’s exquisite fine dining experiences will take you on an equally memorable culinary journey. Sumptuous buffet spreads featuring a selection of Asian, European, and Maldivian favourites. Live cooking stations, grills, fresh seafood, and delicious desserts. Coupledwith light all-day meals and specialty restaurants in Maldives are sure to win over your taste buds.</p><p>A highlight of resort is Pier Six – the overwater restaurant is built on Atmosphere Kanifushi’s new overwater peninsula, offering an array of seafood specialties including delicacies like Tuna Tataki with Mango Salsa, Crab and Chives Dumplings, and local specials, such as the Banana Leaf Wrapped Steamed Reef Fish and Japanese Teppanyaki grill options. Choose from gluten-free and vegan options as well as delicious beef and chicken dishes.</p>"]
    
    const presstextsix = ["<p>The Maldives evoke a picture of pristine beaches, clear turquoise lagoons, and reef-ringed atolls with colourful marine life – the ideal setting for adventure, exploration, and indulgent experiences.</p><p>You can swim in the lagoon with turtles and colourful reef fish or be inspired by the hues of a Maldivian sunset out in the sea. Additionally, you can experience the exhilaration of water sports with your family with a range of motorised and non-motorised options. A myriad of such memorable experiences awaits you at Atmosphere Kanifushi.</p><p>If you fancy an enhanced relaxing experience, Akiri Spa by Mandara offers massages and treatments that can help you unwind and balance your mind and body with a range of treatments fusing spa traditions from India, Bali, Hawaii, and Thailand.</p>"]

    const titleone = "Hassle-free holiday plan"
    const titletwo = "Private Villas offering the best of Island living"
    const titlethree = "Watch the sun in all its glory"
    const titlefour = "Exotic culinary experiences"
    const titlefive = "Fun-filled and relaxing vacation"

    const thumCardData = [
        {
            id: 0, thumbCardImage: thumbOne, 
            thumbCardTitle: 'Enjoy the best of the Maldives in complete privacy at the Atmosphere Kanifushi', 
            thumbCardDate: '10/11/2023',
            thumbPressLink:'/connect-with-nature-and-cultivate-rejuvenation-at-atmosphere-kanifushi-in-the-maldives'
        },
        
        {
            id: 1, 
            thumbCardImage: thumbThree, 
            thumbCardTitle: 'How To Elevate Your Plant-Based Cooking This Summer Season', 
            thumbCardDate: '08/11/2023',
            thumbPressLink:'/how-to-elevate-your-plant-based-cooking-this-summer-season'
        },

        {
            id: 2, 
            thumbCardImage: thumbFour, 
            thumbCardTitle: 'Atmosphere Kanifushi Enriches Culinary Experiences With The New Lantern Bar', 
            thumbCardDate: '05/11/2023',
            thumbPressLink:'/atmosphere-kanifushi-enriches-culinary-experiences-with-the-new-lantern-bar'
        }
    ]

  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Press Releases</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/media-center'>Media</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Enjoy the best of the Maldives in complete privacy at the Atmosphere Kanifushi</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='press-details section-padding'>
                <div className='container'> 
                    <div className='row'>
                        <div className='col-12 mb-4'>
                            <Link className='w-auto btn primary theme-btn px-4 py-2 theme-outline-btn fw-medium text-uppercase border-theme form-control' to='/media-center'><FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Back </Link>
                        </div>
                        <div className='col-md-8'>
                            <div className='press-article'>
                                <div className='press-img rounded-3 overflow-hidden'>
                                    <img className='w-100' src={pressDetail} alt=''/>
                                </div>
                                <div className='d-flex justify-content-between flex-wrap'>
                                    <div className='press-title'>
                                        <h3 className='georgia text-uppercase'>Enjoy the best of the Maldives in complete privacy at the Atmosphere Kanifushi</h3>
                                        <span className='fw-medium mt-2 d-flex'>Dec 04, 2023 02:46 PM IST</span>
                                    </div>
                                    <div className='press-date pt-lg-4'>
                                        <span>25/10/2023</span>
                                    </div>
                                </div>
                                <div className='press-full-article'>
                                    <div className='press-text-card'>
                                        <div dangerouslySetInnerHTML={{ __html: presstextone }}></div>
                                        <h5 className='fw-semibold mt-4'>{titleone}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstexttwo }}></div>
                                        <h5 className='fw-semibold mt-4'>{titletwo}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextthree }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlethree}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextfour }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlefour}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextfive }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlefive}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextsix }}></div>
                                    </div>
                                    {/* /press-text-card */}
                                </div>
                            </div>
                            <div className='press-article-pagination'>
                                <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control' to=''><FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Prev</Link>
                                <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control ms-3' to=''>Next <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                            </div>
                        </div>
                        {/* /col-md-8 */}
                        <div className='col-md-4'>
                            <div className='more-articles-sidebar mt-md-0 mt-5'>
                                <div className='dashed-heading position-relative'>
                                    <h5 className='georgia text-uppercase'><span>More articles</span></h5>
                                </div>
                                <div className='thumb-articles mt-md-4 mt-3 pt-md-2'>
                                    {thumCardData.map((thumbCard) =>(
                                        <ThumbPressItem
                                            key={thumbCard.id}
                                            thumbCardImage={thumbCard.thumbCardImage}
                                            thumbCardTitle={thumbCard.thumbCardTitle}
                                            thumbCardDate={thumbCard.thumbCardDate}
                                            thumbPressLink={thumbCard.thumbPressLink}
                                        />  
                                    ))}                                    
                                </div>
                                <div className='press-article-pagination'>
                                    <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control' to='/media-center'> VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                </div>
                            </div>
                        </div>
                        {/* /col-md-4 */}
                    </div>
                </div>
            </div>
        </section>


    </>
  )
}

export default KanifushiEnjoyTheBestOfTheMaldives