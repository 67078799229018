import React from 'react'
import { Link } from 'react-router-dom';
import SustainFeatureBox from './SustainFeatureBox';

function Sustainability() {
    const images = [{
        galleryHeading:"Inspired by Nature", galleryPara:"Lorem Ipsum is simply dummy text of the printing typesetting. Lorem Ipsum is simply dummy text of the printing typesetting."}
    ]
  return (
    <section>
        <div className='sustainability section-padding pt-0'>
            <div className='container'>
                <div className='sustainability-commitment'> 
                    <div className='row'>
                        <div className='col-12 text-center text-white'>
                            <span className='text-text-uppercase text-white'>SUSTAINABILITY</span>
                            <h2 className='h1 text-uppercase georgia my-lg-4 my-2'>OUR GREEN INITIATIVES</h2>
                            <p className='text-white m-auto'>Sustainability is at the heart of our business and the experience we offer. We are always working towards implementing new initiatives and action plans to optimise water and energy use and improve waste management.</p>
                            <div className='sustain-action mt-5'>
                                <Link to="/sustainability" className='btn primary theme-btn fw-medium text-white text-uppercase border-theme'>LEARN MORE</Link>
                            </div>                       
                        </div>
                    </div>
                </div>
            </div>
            <div className='commitment-feature text-center'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <SustainFeatureBox images={images}/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Sustainability