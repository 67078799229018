import React from 'react'
import parse from 'html-react-parser'
import pressReleases from '../assets/images/awards-badge.svg'
import { Form } from 'react-bootstrap'
import AwardCard from './AwardCard'
import awardOne from '../assets/images/award-one.png'
import awardTwo from '../assets/images/award-two.png'
import awardThree from '../assets/images/award-three.png'
import awardFour from '../assets/images/award-four.png'
import awardFive from '../assets/images/award-five.png'
import awardSix from '../assets/images/award-six.png'
import awardSeven from '../assets/images/award-seven.png'
import awardEight from '../assets/images/award-eight.png'
import awardNine from '../assets/images/award-nine.png'
import awardTen from '../assets/images/award-ten.png'
import awardEleven from '../assets/images/award-eleven.png'
import awardTwelve from '../assets/images/award-twelve.png'
import awardThirteen from '../assets/images/award-thirteen.png'
import awardFourteen from '../assets/images/award-fourteen.png'
import awardFifteen from '../assets/images/award-fifteen.png'
import awardSixteen from '../assets/images/award-sixteen.png'
import awardSeventeen from '../assets/images/award-seventeen.png'
import awardEighteen from '../assets/images/award-eighteen.png'
import awardNineteen from '../assets/images/award-nineteen.png'
import awardTwenty from '../assets/images/award-twenty.png'
import awardTwentyOne from '../assets/images/award-twenty-one.png'
import awardTwentyTwo from '../assets/images/award-twenty-two.png'
import awardTwentyThree from '../assets/images/award-twenty-three.png'
import awardTwentyFour from '../assets/images/award-twenty-four.png'
import awardTwentyFive from '../assets/images/award-twenty-five.png'
import awardLogoOne from '../assets/images/tripadvisor.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function MediaAwards({smallHeading = 'AWARDS',heading= 'AWARDS'}) {
  const para = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"

  const awardData = [
    {
      id: 0,
      awardImage: awardOne,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Tripadvisor Global'
    },
    {
      id: 1,
      awardImage: awardTwo,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Tez Worldberry Russia'
    },
    {
      id: 2,
      awardImage: awardThree,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 4,
      awardImage: awardFour,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Tez Worldberry Russia'
    },
    {
      id: 5,
      awardImage: awardFive,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 6,
      awardImage: awardSix,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Tripadvisor Global'
    },
    {
      id: 7,
      awardImage: awardSeven,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Tez Worldberry Russia'
    },
    {
      id: 8,
      awardImage: awardEight,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 9,
      awardImage: awardNine,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 10,
      awardImage: awardTen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 11,
      awardImage: awardEleven,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 12,
      awardImage: awardTwelve,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 13,
      awardImage: awardThirteen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 14,
      awardImage: awardFourteen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 15,
      awardImage: awardFifteen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 16,
      awardImage: awardSixteen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 17,
      awardImage: awardSeventeen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 18,
      awardImage: awardEighteen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 19,
      awardImage: awardNineteen,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 20,
      awardImage: awardTwenty,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 21,
      awardImage: awardTwentyOne,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 22,
      awardImage: awardTwentyTwo,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 23,
      awardImage: awardTwentyThree,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 24,
      awardImage: awardTwentyFour,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    },
    {
      id: 25,
      awardImage: awardTwentyFive,
      awardLogo: awardLogoOne,
      awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
    }
  ]

  return (
    <>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                  <img src={pressReleases} title='Atmosphere Living' alt='Atmosphere Living' />
              </div>
              <div className='small-heading'>
                  <span className='text-uppercase'>{smallHeading}</span>
              </div>                      
              <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{heading}</h1>
              <div className='media-para m-auto pt-3 text-center'>
                <p>
                  {parse(para)}
                </p>
              </div>
            </div> 
            <div className='col-12'>
              <form action='#' method='get'>
                <div className='d-flex align-items-center flex-wrap justify-content-center my-xl-5 my-4'>
                  <div className='select-form w-100'>
                    <Form.Select className='form-control form-select shadow-none' aria-label="Default select example">
                    <option>Select Sub Brand</option>
                    <option value="1">Atmosphere</option>
                    <option value="2">By Atmosphere</option>
                    <option value="3">An Atmosphere Experience</option>
                    <option value="4">A Signature Atmosphere</option>
                    <option value="5">Elements of Nature by Atmosphere</option>
                    <option value="6">Haritage by Atmosphere</option>
                    <option value="7">An Urban Atmosphere</option>
                  </Form.Select>
                  </div>
                  <div className='select-form w-100'>
                    <Form.Select className='form-control form-select shadow-none' aria-label="Default select example">
                    <option>Select Hotel/Resort</option>
                    <option value="1">Kanifushi</option>
                    <option value="2">Varu</option>
                  </Form.Select>
                  </div>
                </div>
              </form>
            </div>
            {/* col-12 */}
          </div>
          <div className='awards-list'>
            <div className='row gy-4'>
              {awardData.map((award) => (
                <div className='col-lg-4 col-md-6' key={award.id}>
                  <AwardCard                  
                    awardImage={award.awardImage}
                    awardLogo={award.awardLogo}
                    awardCompanyName={award.awardCompanyName}
                  />
                  </div>
                ))}                
              </div>
              <div className='show-more d-flex justify-content-center d-none'>
                <Link to='/' className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control'>Show More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
              </div>
            </div>
        </div>
    </>
  )
}

export default MediaAwards