import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';

function OfferSlider({images,classview='',gallerySettings}) {
    const settings = {...gallerySettings}
    
  return (
    <Slider {...settings}>
        
            {images.map(image=>(
              <div className='gallery-card'>
                <div className={`card-image overflow-hidden ${classview}`}>
                  <img className='w-100' src={image.imageUrl} alt='Our Gallery' title='Our Gallery' />
                </div>
                <div className='gallery-text-box rounded-4 ms-auto me-auto text-center'>
                    <small className='text-uppercase'>{image.offerSmallText}</small>
                  <h4 className='georgia mt-3 mb-3'>{image.galleryHeading}</h4>
                  <span>{image.offerSmallHeading}</span>
                  <p>{parse(image.galleryPara)}</p>
                  <div className='book-offer mt-4'>
                    <Link to={image.link} target={image.targetLink} className='btn primary theme-btn fw-medium text-white text-uppercase border-theme'>Book Now</Link>
                  </div>
                </div>
            </div>
            ))}
            
    </Slider>
  )
}

export default OfferSlider