import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/offers-cover.jpg'
import pressDetail from '../assets/images/press-one.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ThumbPressItem from './ThumbPressItem';
import thumbFour from '../assets/images/thumb-four.jpg'
import thumbTwo from '../assets/images/thumb-two.jpg'
import thumbThree from '../assets/images/thumb-three.jpg'
import { Link } from 'react-router-dom';

function KanifushiConnectWithNatureAndCultivateRejuvenation() {
    const presstextone = ["<p>Prioritise balance and wellbeing with an escape to a secluded island getaway, surrounded by azure waters of the Indian Ocean. Atmosphere Kanifushi is a quiet sanctuary where you can reconnect with yourself, your loved ones, and the natural world.</p><p>Situated in the Lhaviyani Atoll, just 30 minutes via a scenic seaplane transfer from the Malé International Airport, this Maldivian retreat offers mesmerizing vistas and numerous opportunities for tapping into serenity. It is an ideal stay for those looking to counterbalance busy modern life with a deeply relaxing escape amidst pristine beaches and clear turquoise lagoons, while still enjoying the luxury of fabulous amenities and experiences.</p>"]
    
    const presstexttwo = ["<p>Enchanting views from sunrise to sunset invite you to laze in the soft glow of the sun, naturally resetting your circadian rhythm. In the evenings, you can catch the crimson streaks of the sun setting at the horizon from the deck of a sunset view villa, or from the gently lapping waters of the adults-only pool at the Sunset Pool Bar.</p><p>For an even more serene evening experience, set off on a Dhoni, a traditional Maldivian sailing vessel, and literally sail into the sunset. The distinctive Maldivian hues of blues and turquoise transform into romantic red, pink, purple, and deep orange as you float across the sea.</p>"]
    
    const presstextthree = ["<p>The resort offers ample opportunities to reflect and unwind away from the daily buzz of the city. Take in the breathtaking sunrise views at the secluded far eastern end of the island. Fitness enthusiasts can also enjoy a meditative morning run on the 2 km long beach and shaded island paths. For deeper relaxation, book a treatment at Akiri Spa By Mandara specialising in Ayurveda therapies, signature Mandara body and facial treatments, and body scrubs.</p>"]
    
    const presstextfour = ["<p>Atmosphere Kanifushi delights with its white, sandy beaches, swaying palm trees, and lush gardens, reminding visitors of nature’s natural healing powers.</br>Wake up to the sound of birdsong and ocean waves. Soothe your nervous system by sipping in the clean air while the water laps gently on the turquoise lagoon. Explore the stunning natural marine ecosystems with a sunset fishing trip or gain mental clarity with a leisurely stand-up paddle.</p>"]
    
    const presstextfive = ["<p>Greet the morning with a laid-back, delicious floating breakfast spread served in the comfort of your private pool. If you are an early riser, start your day with breakfast amidst tranquility at the inviting outdoor deck of The Spice restaurant. Awash in hues of orange and yellow, the spot showcases gorgeous views of the sun waking up over the horizon.</p><p>For lunch and dinner, you can sample cuisines as diverse as Japanese Teppanyaki grills, Mediterranean dishes, Sri Lankan street food, or dine from an entirely vegetarian menu, a first in the Maldives.</br>Leave the island recharged with newfound energy, hope, and positivity – alive with wellness from the inside out.</p>"]

    const titleone = "Connect to nature’s rhythm"
    const titletwo = "Rejuvenate yourself"
    const titlethree = "Reconnect with nature"
    const titlefour = "Nourish yourself"

    const thumCardData = [
        {
            id: 0, thumbCardImage: thumbTwo, 
            thumbCardTitle: 'Enjoy the best of the Maldives in complete privacy at the Atmosphere Kanifushi', 
            thumbCardDate: '10/11/2023',
            thumbPressLink:'/enjoy-the-best-of-the-maldives-in-complete-privacy-at-the-atmosphere-kanifushi'
        },
        
        {
            id: 1, 
            thumbCardImage: thumbThree, 
            thumbCardTitle: 'How To Elevate Your Plant-Based Cooking This Summer Season', 
            thumbCardDate: '08/11/2023',
            thumbPressLink:'/how-to-elevate-your-plant-based-cooking-this-summer-season'
        },

        {
            id: 2, 
            thumbCardImage: thumbFour, 
            thumbCardTitle: 'Atmosphere Kanifushi Enriches Culinary Experiences With The New Lantern Bar', 
            thumbCardDate: '05/11/2023',
            thumbPressLink:'/atmosphere-kanifushi-enriches-culinary-experiences-with-the-new-lantern-bar'
        }
    ]

  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Press Releases</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/media-center'>Media</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Connect with nature and cultivate rejuvenation at Atmosphere Kanifushi in the Maldives</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='press-details section-padding'>
                <div className='container'> 
                    <div className='row'>
                        <div className='col-12 mb-4'>
                            <Link className='w-auto btn primary theme-btn px-4 py-2 theme-outline-btn fw-medium text-uppercase border-theme form-control' to='/media-center'><FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Back </Link>
                        </div>
                        <div className='col-md-8'>
                            <div className='press-article'>
                                <div className='press-img rounded-3 overflow-hidden'>
                                    <img className='w-100' src={pressDetail} alt=''/>
                                </div>
                                <div className='d-flex justify-content-between flex-wrap'>
                                    <div className='press-title'>
                                        <h3 className='georgia text-uppercase'>Connect with nature and cultivate rejuvenation at Atmosphere Kanifushi in the Maldives</h3>
                                        <span className='fw-medium mt-2 d-flex'>Dec 04, 2023 02:46 PM IST</span>
                                    </div>
                                    <div className='press-date pt-lg-4'>
                                        <span>25/10/2023</span>
                                    </div>
                                </div>
                                <div className='press-full-article'>
                                    <div className='press-text-card'>
                                        <div dangerouslySetInnerHTML={{ __html: presstextone }}></div>
                                        <h5 className='fw-semibold mt-4'>{titleone}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstexttwo }}></div>
                                        <h5 className='fw-semibold mt-4'>{titletwo}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextthree }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlethree}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextfour }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlefour}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextfive }}></div>
                                    </div>
                                    {/* /press-text-card */}
                                </div>
                            </div>
                            <div className='press-article-pagination'>
                                <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control' to=''><FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Prev</Link>
                                <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control ms-3' to=''>Next <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                            </div>
                        </div>
                        {/* /col-md-8 */}
                        <div className='col-md-4'>
                            <div className='more-articles-sidebar mt-md-0 mt-5'>
                                <div className='dashed-heading position-relative'>
                                    <h5 className='georgia text-uppercase'><span>More articles</span></h5>
                                </div>
                                <div className='thumb-articles mt-md-4 mt-3 pt-md-2'>
                                    {thumCardData.map((thumbCard) =>(
                                        <ThumbPressItem
                                            key={thumbCard.id}
                                            thumbCardImage={thumbCard.thumbCardImage}
                                            thumbCardTitle={thumbCard.thumbCardTitle}
                                            thumbCardDate={thumbCard.thumbCardDate}
                                            thumbPressLink={thumbCard.thumbPressLink}
                                        />  
                                    ))}                                    
                                </div>
                                <div className='press-article-pagination'>
                                    <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control' to='/media-center'> VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                </div>
                            </div>
                        </div>
                        {/* /col-md-4 */}
                    </div>
                </div>
            </div>
        </section>


    </>
  )
}

export default KanifushiConnectWithNatureAndCultivateRejuvenation