import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import innerCover from '.././assets/images/sustainability-cover.jpg'
import ExperienceAwait from '../components/ExperienceAwait';
import starPerformanceImg from '../assets/images/sustainablity-leaf.svg'
import greenGlobe from '../assets/images/green-globe.jpg'
import carbonFootprint from '../assets/images/carbon-footprint.jpg'
import sustainOperations from '../assets/images/sustain-operations.jpg'
import coralConservation from '../assets/images/corel-conservation.jpg'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import greenGlobeIcon from '../assets/images/green-globe122x122.png'
import { motion } from 'framer-motion'

function SustainabilityPage() {
    const para = `At the heart of every stay with Atmosphere Hotels and Resorts lies a commitment to crafting sustainable sanctuaries. We are passionate about serving our guests with experiences to immerse and indulge in the awe-inspiring locale of our properties. We also understand our responsibility towards preserving these experiences for future generations. So our stays are more than a retreat; they’re a conscious choice for a greener tomorrow, with every detail thoughtfully considered to make a difference for our planet, people, and places.`;
    const encironmentPara = `With ‘The joy of giving’  ingrained in the ethos of the Atmosphere Core Group, it stands at the forefront of the hospitality industry as one of the leading companies with the highest solar power generation capacity in the Maldives.`;
    const sustainheadingOne = ['A New Experience Awaits']
    const headingOne= "A stay that makes a difference"
  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Sustainability</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Sustainability</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>
        <ExperienceAwait sustainheading={sustainheadingOne} heading={parse(headingOne)} image={starPerformanceImg} para={para} showLearnMore={false} visitLinks={true}/>

        <section>
            <div className='our-values sustainability-cards'>
                <div className='container'>
                    <div className='row mt-lg-5 mt-4 position-relative'>
                        <div className='col-md-7'>
                            <motion.div 
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className='feature-left-image'>
                                <img className='w-100' src={greenGlobe} alt='Purpose' title='Pupropse' />
                            </motion.div>
                        </div>
                        <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='right-feature-text-col position-absolute bottom-0 end-0 top-0 d-flex align-items-center'>
                            <div className='right-feature-wrap bg-white position-relative'>
                                <div className='top-head-text'>
                                    <h4 className='georgia text-uppercase'>GREEN GLOBE CERTIFIED</h4>
                                </div>
                                <div className='feature-text-para position-relative'>
                                    <p>The internationally recognised Green Globe Certification for environmental sustainability is a rigorous process of working through 44 core environmental criteria and up to 380 compliance indicators, depending on the destination.</p>
                                    <div className='learnmore mt-xl-5 mt-4 d-none'>
                                        <Link to='/coming-soon' className='text-decoration-none text-uppercase'>Learn More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                    </div></div>
                                    <div className='green-globe-icon position-absolute'>
                                        <img src={greenGlobeIcon} alt='Green Globe' title='Green Globe' />
                                    </div>
                                
                            </div>
                        </motion.div>
                    </div>
                    <div className='row position-relative section-padding'>
                        <div className='col-md-7 offset-md-5'>
                            <motion.div 
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className='feature-left-image'>
                                <img className='w-100' src={carbonFootprint} alt='Purpose' title='Pupropse' />
                            </motion.div>
                        </div>
                        <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='right-feature-text-col position-absolute bottom-0 start-0 top-0 d-flex align-items-center'>
                            <div className='right-feature-wrap bg-white'>
                                <div className='top-head-text'>
                                    <h4 className='georgia text-uppercase'>CARBON FOOTPRINTS</h4>
                                </div>
                                <div className='feature-text-para position-relative'>
                                    <p>We have solar panels strategically installed on the rooftops at each property. Harnessing a comprehensive solar power infrastructure, we can effectively mitigate carbon emissions by approximately 2000 tons annually.</p>
                                    <div className='learnmore mt-xl-5 mt-4 d-none'>
                                        <Link to='/coming-soon' className='text-decoration-none text-uppercase'>Learn More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>


        <section>
            <div className='environmental-tourism py-4 py-lg-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className='environmental-tourism-card ms-auto'>
                                <h3 className='text-uppercase'>Leading the Way in Sustainable Hospitality</h3>
                                <p className='mt-4'>{encironmentPara}</p>
                                <div className='environmental-action mt-xl-5 mt-4 d-none'>
                                    <Link to='/coming-soon' className='btn btn-primary theme-btn fw-medium text-white text-uppercase border-theme'>learn more</Link>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section>
            <div className='our-values sustainability-cards section-padding pb-0'>
                <div className='container'>
                    <div className='row position-relative'>
                        <div className='col-md-7'>
                            <motion.div 
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className='feature-left-image'>
                                <img className='w-100' src={sustainOperations} alt='Purpose' title='Pupropse' />
                            </motion.div>
                        </div>
                        <motion.div 
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='right-feature-text-col position-absolute bottom-0 end-0 top-0 d-flex align-items-center'>
                            <div className='right-feature-wrap bg-white'>
                                <div className='top-head-text'>
                                    <h4 className='georgia text-uppercase'>SUSTAINABLE OPERATIONS</h4>
                                </div>
                                <div className='feature-text-para position-relative'>
                                    <p>Our resorts employ energy-saving measures, including energy-efficient equipment, low-energy appliances, optimising energy usage, and using sustainable energy sources.</p>
                                    <div className='learnmore mt-xl-5 mt-4 d-none'>
                                        <Link to='/coming-soon' className='text-decoration-none text-uppercase'>Learn More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div className='row position-relative section-padding'>
                        <div className='col-md-7 offset-md-5'>
                            <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className='feature-left-image'>
                                <img className='w-100' src={coralConservation} alt='Purpose' title='Pupropse' />
                            </motion.div>
                        </div>
                        <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='right-feature-text-col position-absolute bottom-0 start-0 top-0 d-flex align-items-center'>
                            <div className='right-feature-wrap bg-white'>
                                <div className='top-head-text'>
                                    <h4 className='georgia text-uppercase'>Reducing Plastic</h4>
                                </div>
                                <div className='feature-text-para position-relative'>
                                    <p>For the well-being of the Indian Ocean and our planet, our resorts combat plastic pollution with on-site water bottling plants, eliminating the need for plastic bottled water on the islands. </p>
                                    <div className='learnmore mt-xl-5 mt-4 d-none'>
                                        <Link to='/coming-soon' className='text-decoration-none text-uppercase'>Learn More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>


    </>
  )
}

export default SustainabilityPage