import './App.css';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss'
import './assets/scss/responsive.scss'
import Footer from './components/Footer';
import { Home } from './pages/home/Home';
import AllRoutes from './allRoutes/AllRoutes';

function App() {
  return (
    <>
      <Header/>
      <AllRoutes/>
      <Footer/>
    </>
  );
}

export default App;
