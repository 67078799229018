import React, { useState } from 'react';
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import OffersTabItem from './OffersTabItem'
import coverOne from '../assets/images/lowest-price-assurance.jpg'
import coverTwo from '../assets/images/festival-season.jpg'
import coverThre from '../assets/images/unforgettable-festivities.jpg'
import coverFour from '../assets/images/varu-lowest-price-assurance.jpg'
import TabImgOne from '../assets/images/all-offers.jpg'
import TabImgTwo from '../assets/images/kanifushi-offers.jpg'
import TabImgThree from '../assets/images/varu-tabs.jpg'
import TabImgFour from '../assets/images/marinagate-tabs.jpg'
// import TabImgFive from '../assets/images/lalgarh-tabs.jpg'


const OffersTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabsArray = [
    {coverId:0,offerImage:coverOne,offerHeadingOne: "Book Direct – Lowest Price Assurance",offerSmallHeading:"20% discount on Booking",tabPara:"Save 20% on a beautiful picture-postcard holiday in the pristine Lhaviyani Atoll. With assured all-day-long... ",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'Kanifushi',tabCircleImage: TabImgOne,
    offerLink:[{id: 1, text: 'Learn More', url: '/kanifushi-book-direct'}],
    bookLink:[{id: 1, text: 'Book Now', url: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms'}],
  },

    {coverId:1,offerImage:coverTwo,offerHeadingOne: "Celebrate the Season of Splendor",offerSmallHeading:"20% Discount for 4 Nights Booking",tabPara:"This holiday season, immerse yourself in the warmth of our hospitality and the joyous atmosphere that permeates every corner... ",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'Kanifushi',tabCircleImage: TabImgTwo,
    offerLink:[{id: 2, text: 'Learn More', url: '/kanifushi-celebrate-the-season'}],
    bookLink:[{id: 2, text: 'Book Now', url: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms'}],
  },

    {coverId:2,offerImage:coverFour,offerHeadingOne: "Book Direct – Lowest Price Assurance",offerSmallHeading:"All-Inclusive Holiday- VARU PLAN™",tabPara:"Experience the ultimate luxury getaway in the Maldives by booking directly with us! As our valued guest...",offerHotelLocation:"Varu - MALDIVES", name: 'Varu',tabCircleImage: TabImgFour,
    offerLink:[{id: 3, text: 'Learn More', url: '/varu-book-direct'}],
    bookLink:[{id: 3, text: 'Book Now', url: 'https://reservations.varu-atmosphere.com/110184?RatePlanId=3993028&utm_source=website&utm_medium=bookdirect&utm_campaign=book-direct&_gl=1*2l9rrd*_gcl_au*MTAxNTY4MjI0Mi4xNzAxODQxNTQx*_ga*MTM0NDA2MDg5MC4xNzAxODQxNTQx*_ga_B69EZC48X7*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.*_ga_CPMMLXTB86*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.&_ga=2.242058946.116210537.1702378580-1344060890.1701841541'}],
  },

    {coverId:3,offerImage:coverThre,offerHeadingOne: "Experience Unforgettable Festivities",offerSmallHeading:"20% Discount for 4 Nights Booking",tabPara:"Indulge in the magic of the festive season at VARU BY Atmosphere, where luxury meets the allure of the Maldives...",offerHotelLocation:"VARU - MALDIVES", name: 'Varu',tabCircleImage: TabImgThree,
    offerLink:[{id: 3, text: 'Learn More', url: '/Experience-Unforgettable-Festivities'}],
    bookLink:[{id: 3, text: 'Book Now', url: 'https://reservations.varu-atmosphere.com/110184?RatePlanId=3993028&utm_source=website&utm_medium=bookdirect&utm_campaign=book-direct&_gl=1*2l9rrd*_gcl_au*MTAxNTY4MjI0Mi4xNzAxODQxNTQx*_ga*MTM0NDA2MDg5MC4xNzAxODQxNTQx*_ga_B69EZC48X7*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.*_ga_CPMMLXTB86*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.&_ga=2.242058946.116210537.1702378580-1344060890.1701841541'}],
  }
  ];
const allTabs = [
  {coverId:0,tabName:"ALL OFFERS",offerImage:coverOne,offerHeadingOne: "25% Off",offerSmallHeading:"SPECIAL OFFER ON DINING",tabPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'Atmosphere',tabCircleImage: TabImgOne},

  {coverId:1,tabName:"Kanifushi",offerImage:coverTwo,offerHeadingOne: "25% Off",offerSmallHeading:"SPECIAL OFFER ON DINING",tabPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'Kanifushi',tabCircleImage: TabImgTwo},

  {coverId:2,tabName:"Varu",offerImage:coverOne,offerHeadingOne: "25% Off",offerSmallHeading:"SPECIAL OFFER ON DINING",tabPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'Varu',tabCircleImage: TabImgThree},

  // {coverId:3,tabName:"MARINA GATE",offerImage:coverOne,offerHeadingOne: "25% Off",offerSmallHeading:"SPECIAL OFFER ON DINING",tabPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'MarinaGate',tabCircleImage: TabImgFour}

]
const onTabClick = (e, index) => {
  console.log(e);
  setActiveTab(index);
};
  return (
    <>
        <Tabs activeTab={activeTab} onTabClick={onTabClick} tabsArray={allTabs}>
        {allTabs.map((item) => (
          <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active text-uppercase d-flex align-items-center' : 'text-uppercase d-flex align-items-center'}`}>
            <span className='tab-feature-img d-flex rounded-circle overflow-hidden'><img src={item.tabCircleImage} alt={item.name} /></span>
            <span className='tab-name'>{item.tabName}</span>
          </Tab>
        ))}
      </Tabs>

  {/* {filteredData.map((screen, index) => ( */}
  {allTabs.map((screen) => (
        <TabScreen
        key={screen.coverId}
        activeTab={activeTab}
        index={screen.coverId}
        className="all-offers-list mt-lg-5 mt-4"
        // You can add animation with adding a custom class
      >
          {tabsArray.filter((item) => (activeTab === 0 ? true : item.name === screen.tabName)).map(data=>(
            <OffersTabItem offerImage={data.offerImage} offerHeadingOne={data.offerHeadingOne} offerSmallHeading={data.offerSmallHeading} tabPara={data.tabPara} offerHotelLocation={data.offerHotelLocation} hotelLink={data.hotelLink} tabCircleImage={data.tabCircleImage} offerLink={data.offerLink} bookLink={data.bookLink} />
          ))}
        </TabScreen>
      ))}
    
    {/* <TabItem/> */}

    </>
  )
}

export default OffersTabs