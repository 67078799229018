import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/offers-cover.jpg'
import pressDetail from '../assets/images/press-three.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ThumbPressItem from './ThumbPressItem';
import thumbOne from '../assets/images/thumb-one.jpg'
import thumbTwo from '../assets/images/thumb-two.jpg'
import thumbFour from '../assets/images/thumb-four.jpg'
import { Link } from 'react-router-dom';

function KanifushiHowToElevateYourPlantBasedCooking() {
    const presstextone = ["<p>If you are conscious of leading a healthier lifestyle and have an interest in incorporating more plant-based foods into your diet but don’t know where to begin, the experts at Just Veg, the Maldives first all-vegetarian restaurant located at Atmosphere Kanifushi Maldives, is here to help.</p><p>Serving an incredibly delicious range of vegetarian dishes, Just Veg is pioneering fresh and healthy plant-based dishes in the Maldives. Guests are consistently wowed by the depth of flavour and variety of ingredients on show at the restaurant, garnering hundreds of 5-star reviews. With summer in full swing and plenty of incredible fruits and vegetables now in season, it’s a great time to be thinking about how to incorporate more delicious plant based foods into diet.</p><p>Vegetarian specialist at Just Veg Restaurant, Mr. Raman Gomathi says: “When reducing your meat intake and instead opting for a diet rich in plant-based foods, studies have shown that you are less likely to contract certain diseases, have lower risk of certain cancers, better weight management, and improved heart health.</p><p>“Overall, a more plant-focused lifestyle can be a health-conscious choice for people who are looking to reduce their risk of certain chronic diseases and maintain a healthy weight. Not only this, it tends to be more affordable and can be just as delicious, particularly in the summer when you can draw on all of the fresh fruits and vegetables in season.”</p><p>Recent research has shown that one in ten Brits (12%) have already made the switch to a flexitarian diet and supermarkets across the country are offering more choice than ever before, so there’s no better time for Mr Gomathi to offer his tips and tricks on incorporating tastier plant-based food into your diet.</p>"]
    
    const presstexttwo = ["<p>Experimenting with herbs and spices can add entirely different tastes to your dishes whilst also bringing out new flavours to the vegetables you are cooking with. For example, a great addition to a vegetarian chilli would be oregano (either dried or fresh), ground cumin, bay leaves, chilli powder, and fresh garlic. For a more distinct flavour, add herbs near the end of cooking, or for more blended flavours, mix these in at the beginning.</p>"]
    
    const presstextthree = ["<p>A simple way to elevate your cooking is by combining a variety of textures, such as crispy with creamy, or adding croutons or nuts to a salad – sprinkling pine nuts over a summer salad is a great way to add crunch. This is also a fantastic opportunity to add more vitamins and minerals to dishes for that extra health boost.</p>"]
    
    const presstextfour = ["<p>Get creative in the kitchen and utilise more cooking techniques to help bring flavour to your cooking. Roasting, grilling or sautéing brings out a variety of different tastes – for example, roasting vegetables, such as onions or peppers for 20-30 minutes will caramelise them, bringing out their natural sweetness and intensifying their tang.</p>"]
    
    const presstextfive = ["<p>Add flavours that touch on each of the five senses of taste- salty, butter, sweet, sour and umami. Where Umami is typically characteristic of broths and cooked meats, the flavour can be achieved in plant-based meals by adding simple ingredients such as mushrooms, miso paste, or soy sauce, enhancing the overall balance of the dish.</p>"]
    
    const presstextsix = ["<p>You might be surprised at the amount of vegetarian dishes there are in different cultures. Opting for Mediterranean staples such as feta or stuffed peppers are a delicious accompaniment to salads. Paneer in Indian cuisine is a key ingredient in many curries and adds an alternative texture, too. Each cuisine incorporates traditional spices and cooking techniques, and therefore offers an exciting way to try your hand at new dishes.</p>"]
    
    const presstextseven = ["<p>Those interested in learning more about plant-based foods can take their education a step further by attending the annual Just Veg Festival, held at Atmosphere Kanifushi Maldives. The four-day festival celebrates contemporary plant-forward cuisine, offering guests a great introduction to what a vegetarian lifestyle could offer.<br>During this four-day culinary event, Michelin Star Chef, Fabrizio Marino, joins guests in the Maldives to bring them a limited-edition gourmet menu curated by himself and the team at Just Veg. Taking place from 16 – 26 October 2023, interested guests can join the celebrations at no additional cost – but places are limited so advance bookings are essential.</p>"]

    const titleone = "Herbs and spices will take your meals to the next level"
    const titletwo = "Textures help create variety and improve mouthfeel"
    const titlethree = "Incorporate a variety of cooking techniques"
    const titlefour = "Ensure to touch on the five senses of taste"
    const titlefive = "Take inspiration from international cuisines"
    const titlesix = "Take your plant-based cooking to new heights"

    const thumCardData = [
        {
            id: 0, thumbCardImage: thumbOne, 
            thumbCardTitle: 'Connect with nature and cultivate rejuvenation at Atmosphere Kanifushi in the Maldives', 
            thumbCardDate: '10/11/2023',
            thumbPressLink:'/connect-with-nature-and-cultivate-rejuvenation-at-atmosphere-kanifushi-in-the-maldives'
        },
        
        {
            id: 1, 
            thumbCardImage: thumbTwo, 
            thumbCardTitle: 'Enjoy the best of the Maldives in complete privacy at the Atmosphere Kanifushi', 
            thumbCardDate: '08/11/2023',
            thumbPressLink:'/enjoy-the-best-of-the-maldives-in-complete-privacy-at-the-atmosphere-kanifushi'
        },

        {
            id: 2, 
            thumbCardImage: thumbFour, 
            thumbCardTitle: 'Atmosphere Kanifushi Enriches Culinary Experiences With The New Lantern Bar', 
            thumbCardDate: '05/11/2023',
            thumbPressLink:'/atmosphere-kanifushi-enriches-culinary-experiences-with-the-new-lantern-bar'
        }
    ]

  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Press Releases</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/media-center'>Media</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>How To Elevate Your Plant-Based Cooking This Summer Season</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='press-details section-padding'>
                <div className='container'> 
                    <div className='row'>
                        <div className='col-12 mb-4'>
                            <Link className='w-auto btn primary theme-btn px-4 py-2 theme-outline-btn fw-medium text-uppercase border-theme form-control' to='/media-center'><FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Back </Link>
                        </div>
                        <div className='col-md-8'>
                            <div className='press-article'>
                                <div className='press-img rounded-3 overflow-hidden'>
                                    <img className='w-100' src={pressDetail} alt=''/>
                                </div>
                                <div className='d-flex justify-content-between flex-wrap'>
                                    <div className='press-title'>
                                        <h3 className='georgia text-uppercase'>HOW TO ELEVATE YOUR PLANT-BASED COOKING THIS SUMMER SEASON</h3>
                                        <span className='fw-medium mt-2 d-flex'>Dec 04, 2023 02:46 PM IST</span>
                                    </div>
                                    <div className='press-date pt-lg-4'>
                                        <span>25/10/2023</span>
                                    </div>
                                </div>
                                <div className='press-full-article'>
                                    <div className='press-text-card'>
                                        <div dangerouslySetInnerHTML={{ __html: presstextone }}></div>
                                        <h5 className='fw-semibold mt-4'>{titleone}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstexttwo }}></div>
                                        <h5 className='fw-semibold mt-4'>{titletwo}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextthree }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlethree}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextfour }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlefour}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextfive }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlefive}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextsix }}></div>
                                        <h5 className='fw-semibold mt-4'>{titlesix}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: presstextseven }}></div>
                                    </div>
                                    {/* /press-text-card */}
                                </div>
                            </div>
                            <div className='press-article-pagination'>
                                <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control' to=''><FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Prev</Link>
                                <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control ms-3' to=''>Next <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                            </div>
                        </div>
                        {/* /col-md-8 */}
                        <div className='col-md-4'>
                            <div className='more-articles-sidebar mt-md-0 mt-5'>
                                <div className='dashed-heading position-relative'>
                                    <h5 className='georgia text-uppercase'><span>More articles</span></h5>
                                </div>
                                <div className='thumb-articles mt-md-4 mt-3 pt-md-2'>
                                    {thumCardData.map((thumbCard) =>(
                                        <ThumbPressItem
                                            key={thumbCard.id}
                                            thumbCardImage={thumbCard.thumbCardImage}
                                            thumbCardTitle={thumbCard.thumbCardTitle}
                                            thumbCardDate={thumbCard.thumbCardDate}
                                            thumbPressLink={thumbCard.thumbPressLink}
                                        />  
                                    ))}                                    
                                </div>
                                <div className='press-article-pagination'>
                                    <Link className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control' to='/media-center'> VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                </div>
                            </div>
                        </div>
                        {/* /col-md-4 */}
                    </div>
                </div>
            </div>
        </section>


    </>
  )
}

export default KanifushiHowToElevateYourPlantBasedCooking