import React from 'react'
import parse from 'html-react-parser'
import pressReleases from '../assets/images/press-releases.svg'
import PressReleaseCard from './PressReleaseCard'
import pressOne from '../assets/images/press-one.jpg'
import pressTwo from '../assets/images/press-two.jpg'
import pressThree from '../assets/images/press-three.jpg'
import pressFour from '../assets/images/press-four.jpg'
import pressFive from '../assets/images/press-five.jpg'
import pressSix from '../assets/images/press-six.jpg'
import pressSeven from '../assets/images/press-seven.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function MediaPressRelease({smallHeading = 'THE LATEST NEWS', heading='PRESS RELEASES',}) {
  const para = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"

  const cardsData = [
    {
      id: 1,
      cardImage: pressOne,
      cardTitle: 'Connect with nature and cultivate rejuvenation at Atmosphere Kanifushi in the Maldives',
      cardDate: '25/10/2023',
      cardDescription: 'September 2023 Travel to the land of tropical bliss to reflect, relax and reconnect Prioritise balance and…',
      cardLink: 'Learn More',
      cardUrl: '/connect-with-nature-and-cultivate-rejuvenation-at-atmosphere-kanifushi-in-the-maldives'
    },
    {
      id: 2,
      cardImage: pressTwo,
      cardTitle: 'Enjoy the best of the Maldives in complete privacy at the Atmosphere Kanifushi',
      cardDate: '25/10/2023',
      cardDescription: 'September 2023 Escape to a secluded island getaway amidst azure Indian Ocean waters and enjoy your dream…',
      cardLink: 'Learn More',
      cardUrl: '/enjoy-the-best-of-the-maldives-in-complete-privacy-at-the-atmosphere-kanifushi'
    },
    {
      id: 3,
      cardImage: pressThree,
      cardTitle: 'How To Elevate Your Plant-Based Cooking This Summer Season',
      cardDate: '25/10/2023',
      cardDescription: 'September 2023 Above picture: Vegetarian specialist Raman Gomathi (left) and Michelin Star Chef Fabrizio Marino (right) If…',
      cardLink: 'Learn More',
      cardUrl: '/how-to-elevate-your-plant-based-cooking-this-summer-season'
    },
    {
      id: 4,
      cardImage: pressFour,
      cardTitle: 'Atmosphere Kanifushi Enriches Culinary Experiences With The New Lantern Bar',
      cardDate: '25/10/2023',
      cardDescription: 'September 2023 The blissfully tropical Maldivian resort, Atmosphere Kanifushi recently unveiled a new overwater bar, poetically named…',
      cardLink: 'Learn More',
      cardUrl: '/atmosphere-kanifushi-enriches-culinary-experiences-with-the-new-lantern-bar'
    },
    {
      id: 5,
      cardImage: pressFive,
      cardTitle: 'AKM Just Veg Hosts Chef Fabrizio Marino',
      cardDate: '25/10/2023',
      cardDescription: 'April 2022 As plant-centric cuisines gain global popularity, this exclusive four-day culinary extravaganza allows guests to savour…',
      cardLink: 'Learn More',
      cardUrl: '/akm-just-veg-hosts-chef-fabrizio-marino'
    },
    {
      id: 6,
      cardImage: pressSix,
      cardTitle: 'Experience Kaage, an Award-Winning Epicurean Escapade This Monsoon in the Maldives!',
      cardDate: '25/10/2023',
      cardDescription: 'September 2023 Embrace Gastronomic Luxury at VARU By Atmosphere As you bask…',
      cardLink: 'Learn More',
      cardUrl: '/experience-kaage-an-award-winning-epicurean-escapade-this-monsoon-in-the-maldives',
    },
    {
      id: 7,
      cardImage: pressSeven,
      cardTitle: 'Chef Mohamed Niyaz On Celebrating Authentic Maldivian Flavours',
      cardDate: '25/10/2023',
      cardDescription: 'September 2023 Introducing the culinary genius who brings alive Kaagé specialty restaurant at VARU By Atmosphere. Chef…',
      cardLink: 'Learn More',
      cardUrl: '/chef-mohamed-niyaz-on-celebrating-authentic-maldivian-flavours',
    }
  ];

  return (
    <>
        <div className='container'>
          <div className='row'>
              <div className='col-12 text-center'>
                  <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                      <img src={pressReleases} title='Atmosphere Living' alt='Atmosphere Living' />
                  </div>
                  <div className='small-heading'>
                      <span className='text-uppercase'>{smallHeading}</span>
                  </div>                      
                  <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{heading}</h1>
                  <div className='media-para m-auto pt-3 text-center'>
                    <p>
                        {parse(para)}
                    </p>
                  </div>
              </div>                 
              <div className='press-releases-cards mt-lg-5 mt-4'>
                <div className='row gy-5'>
                  {cardsData.map((card) => (
                    <div className='col-lg-6' key={card.id}>
                      <PressReleaseCard
                        cardImage={card.cardImage}
                        cardTitle={card.cardTitle}
                        cardDate={card.cardDate}
                        cardDescription={card.cardDescription}
                        cardLink={card.cardLink}
                        cardUrl={card.cardUrl}
                      />
                    </div>
                  ))}

                  <div className='show-more d-flex justify-content-center d-none'>
                    <Link to='/' className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control'>Show More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                  </div>

                </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default MediaPressRelease