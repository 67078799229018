import React from 'react'
import { useState } from 'react';
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Modals from '../components/Modals';
import { motion } from 'framer-motion';

function OffersTabItem({offerImage, offerHeadingOne, offerSmallHeading, tabPara, offerHotelLocation, offerLink, bookLink, hotelName, hotelLocation, brandtabs, tabResorts}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>  
        <div className='tab-top-header position-relative'>
            <div className='container'>
                <div className='row position-relative'>
                    <div className='col-md-9'>
                        <motion.div
                        key={Math.random()}
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='offer-img'>
                            <img className='w-100' src={offerImage} alt='Offer' title='Offer' />
                        </motion.div>
                    </div>
                    <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className='offer-info-card right-feature-text-col position-absolute top-0 bottom-0 m-auto d-flex align-items-center'>
                        <div className='hotel-heading-wrap text-center right-feature-wrap bg-white'>
                            <span className='text-uppercase'>{offerHotelLocation}</span>
                            <h3 className='text-uppercase georgia heading-space mt-2'>{offerHeadingOne}</h3>
                            <span>{offerSmallHeading}</span>                           
                            <p>{parse(tabPara)}
                            {offerLink &&
                                offerLink.map((downloadLink) => (
                                    <Link  key={downloadLink.id}
                                    className='text-decoration-underline view-all'
                                    title='Course Link'
                                    to={downloadLink.url}
                                    >
                                    {downloadLink.text}{' '}
                                        <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className='ms-1 icon-font'
                                        />
                                    </Link>
                                ))}
                            {/* <Link to='/kanifushi-book-direct' className='text-decoration-underline view-all' title='Course Link'>Learn More <FontAwesomeIcon icon={faArrowRight} className='ms-1 icon-font' /></Link> */}
                            
                            </p>
                            <div className='offer-book-now'>
                                {bookLink &&
                                bookLink.map((booknowLink) => (
                                    <Link key={booknowLink.id}
                                    className='btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary'
                                    title='Course Link'
                                    to={booknowLink.url}
                                    target='_blank'
                                    >
                                    {booknowLink.text}{' '}
                                    </Link>
                                ))}
                            </div>
                            {/* <Button className='btn primary theme-btn fw-medium text-white text-uppercase border-theme' onClick={handleShow}>Book Now</Button> 
                            <Modals show={show} handleClose={handleClose}/> */}
                        </div>
                    </motion.div>
                </div>


            </div>
        </div>        
    </>
  );
}

export default OffersTabItem