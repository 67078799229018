import axios from "axios";

const apiKey = process.env.REACT_APP_APIKEY;

export const getResortData = async ()=>{
    try {
        const {data } = await axios.get(`${apiKey}/other/get-ahr-resorts-list`);
        return data
    } catch (error) {
        throw Error(error.response.message)
    }
}