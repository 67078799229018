import React from 'react'
import brandOne from '../assets/images/brand-1.jpg'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function FeatureCard({cardFeatureImage,cardBg,cardHeading,cardParagraph,cardUrl,cardLinkName})

{
  return (
    <>
        <div className='col-xl-4 col-md-6'>
            <div className='feature-card position-relative overflow-hidden rounded-4'>
                <div className='card-image overflow-hidden'>
                    <img className='w-100' src={cardFeatureImage} alt='Sub Brand' title='Sub Brand' />
                </div>
                <div className={`sub-card z-1 position-absolute text-center overflow-hidden smooth ${cardBg }`}>
                    <div className={`sub-card-title smooth d-flex justify-content-center align-items-center text-white georgia text-uppercase`}>
                        {cardHeading}
                    </div>
                    <div className='sub-card-para smooth'>
                        <p>{parse(cardParagraph)}</p>
                        {/* <hr className={`${cardBorder }`} /> */}
                        <div className='card-link mt-md-4 mt-2 mb-3 smooth position-relative'>
                            <Link to={cardUrl}>{cardLinkName}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default FeatureCard