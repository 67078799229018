import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/lowest-price-assurance-cover.jpg'
import largeOffer from '.././assets/images/lowest-price-assurance.jpg'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import Modals from '../components/Modals';

function KanifushiBookDirect() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>OFFERS</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/offers'>Offers</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Book Direct – Lowest Price Assurance</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='offer-details section-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='small-heading text-center mb-4'>KANIFUSHI - MALDIVES</div>
                            <div class="dashed-heading position-relative"><h2 class="georgia text-uppercase text-center mb-0"><span className='px-4'>20% discount on Booking</span></h2></div>
                            <h2 class="georgia text-uppercase text-center mb-0 mt-4 fw-bold">Book Direct – Lowest Price Assurance</h2>
                        </div>
                        <div className='row mt-lg-5 mt-4'>
                            <div className='col-12'>
                                <div className='large-img-offer'>
                                    <img className='w-100' src={largeOffer} alt='Offer' title='Offer' />
                                </div>
                                <div className='offer-para mt-4 pt-2'>
                                    <p>Save 20% on a beautiful picture-postcard holiday in the pristine Lhaviyani Atoll. With assured all-day-long sunshine and clear blue skies of the high season, spend memorable moments with your loved ones. Immerse in the natural beauty and luxurious experiences of Atmosphere Kanifushi.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-5 mt-4'>
                            <div className='col-lg-6 pe-lg-4'>
                                <div class="dashed-heading position-relative"><h4 class="georgia text-uppercase mb-0"><span className='pe-3'>HIGHLIGHTS</span></h4></div>
                                <div className='offer-points mt-lg-4 mt-3'>
                                    <ul className='m-0'>
                                        <li><span>20% discount on Booking</span></li>
                                        <li><span>All-Inclusive Kanifushi Plan<sup>™</sup> inclusions</span></li>
                                        <li><span>Complimentary Roundtrip transfers</span></li>
                                        <li><span>Dine Around Options for a Gastronomic Adventure</span></li>
                                        <li><span>Experiences Inclusive with Free Snorkeling & Water Sports</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6 ps-lg-4 mt-lg-0 mt-md-5 mt-4'>
                                <div class="dashed-heading position-relative"><h4 class="georgia text-uppercase mb-0"><span className='pe-3'>TERMS & CONDITIONS</span></h4></div>
                                <div className='offer-points mt-lg-4 mt-3'>
                                    <ul className='m-0'>
                                        <li><span>*Subject to availability</span></li>
                                        <li><span>Minimum 4 nights stay</span></li>
                                        <li><span>For Stays Until 31st October 2024</span></li>
                                        <li><span>This offer cannot be combined with any other special offer</span></li>
                                        <li><span>Standard cancelations and payments as per resort policy</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 mt-xl-5 mt-4'>
                                <div className='offer-book-action'>
                                <Button className='btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary' onClick={handleShow}>Book Now</Button>
                                <Modals show={show} handleClose={handleClose}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default KanifushiBookDirect