import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from '../pages/home/Home'
import OurStory from '../pages/OurStory'
import OurSubBrandsPage from '../pages/OurSubBrandsPage'
import ContactUs from '../pages/ContactUs'
import SustainabilityPage from '../pages/SustainabilityPage'
import Offers from '../pages/Offers'
import OfferDetail from '../pages/OfferDetail'
import Media from '../pages/Media'
import CoomingSoon from '../pages/CoomingSoon'
import KanifushiBookDirect from '../pages/KanifushiBookDirect'
import VaruBookDIrect from '../pages/VaruBookDIrect'
import KanifushiCelebrateTheSeason from '../pages/KanifushiCelebrateTheSeason'
import ExperienceUnforgettableFestivities from '../pages/ExperienceUnforgettableFestivities'
import ConnectWithNatureAndCultivateRejuvenation from '../pages/KanifushiConnectWithNatureAndCultivateRejuvenation'
import KanifushiEnjoyTheBestOfTheMaldives from '../pages/KanifushiEnjoyTheBestOfTheMaldives'
import KanifushiHowToElevateYourPlantBasedCooking from '../pages/KanifushiHowToElevateYourPlantBasedCooking'
import KanifushiEnrichesCulinaryExperiencesWithTheNewLanternBar from '../pages/KanifushiEnrichesCulinaryExperiencesWithTheNewLanternBar'
import KanifushiAKMJustVegHostsChefFabrizioMarino from '../pages/KanifushiAKMJustVegHostsChefFabrizioMarino'
import VaruExperienceKaageAnAwardWinningEpicureanEscapade from '../pages/VaruExperienceKaageAnAwardWinningEpicureanEscapade'
import VaruChefMohamedNiyazOnCelebratingAuthenticMaldivianFlavours from '../pages/VaruChefMohamedNiyazOnCelebratingAuthenticMaldivianFlavours'

function AllRoutes() {
  return (
    <>
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/our-story' element={<OurStory/>}></Route>
            <Route path='/our-brands-page' element={<OurSubBrandsPage/>}></Route>
            <Route path='/contact-us' element={<ContactUs/>}></Route>
            <Route path='/sustainability' element={<SustainabilityPage/>}></Route>
            <Route path='/offers' element={<Offers/>}></Route>
            <Route path='/offer-details' element={<OfferDetail/>}></Route>
            <Route path='/kanifushi-book-direct' element={<KanifushiBookDirect/>}></Route>
            <Route path='/kanifushi-celebrate-the-season' element={<KanifushiCelebrateTheSeason/>}></Route>
            <Route path='/varu-book-direct' element={<VaruBookDIrect/>}></Route>
            <Route path='/Experience-Unforgettable-Festivities' element={<ExperienceUnforgettableFestivities/>}></Route>
            <Route path='/media-center' element={<Media/>}></Route>
            <Route path='/connect-with-nature-and-cultivate-rejuvenation-at-atmosphere-kanifushi-in-the-maldives' element={<ConnectWithNatureAndCultivateRejuvenation/>}></Route>
            <Route path='/enjoy-the-best-of-the-maldives-in-complete-privacy-at-the-atmosphere-kanifushi' element={<KanifushiEnjoyTheBestOfTheMaldives/>}></Route>
            <Route path='/how-to-elevate-your-plant-based-cooking-this-summer-season' element={<KanifushiHowToElevateYourPlantBasedCooking/>}></Route>
            <Route path='/atmosphere-kanifushi-enriches-culinary-experiences-with-the-new-lantern-bar' element={<KanifushiEnrichesCulinaryExperiencesWithTheNewLanternBar/>}></Route>
            <Route path='/akm-just-veg-hosts-chef-fabrizio-marino' element={<KanifushiAKMJustVegHostsChefFabrizioMarino/>}></Route>
            <Route path='/experience-kaage-an-award-winning-epicurean-escapade-this-monsoon-in-the-maldives' element={<VaruExperienceKaageAnAwardWinningEpicureanEscapade/>}></Route>
            <Route path='/chef-mohamed-niyaz-on-celebrating-authentic-maldivian-flavours' element={<VaruChefMohamedNiyazOnCelebratingAuthenticMaldivianFlavours/>}></Route>
            <Route path='/coming-soon' element={<CoomingSoon/>}></Route>
        </Routes>
    </>
  )
}

export default AllRoutes