import React from 'react'
import sustainImgOne from '../assets/images/green-globe.png'
import sustainImgTwo from '../assets/images/carbon-footprints.svg'
import sustainImgThree from '../assets/images/sustainable-operations.svg'

function SustainFeatureBox() {
    const images = [
        {sustainFeatureImg: sustainImgOne, sustainHeading:"Green Globe Certified", sustainPara: "All six of our properties have attained this prestigious certification, reflecting our commitment to sustainability."},

        {sustainFeatureImg: sustainImgTwo, sustainHeading:"Carbon Footprints", sustainPara: "The extensive use of solar energy across all our resorts helps us to mitigate and reduce our carbon emissions."},

        {sustainFeatureImg: sustainImgThree, sustainHeading:"Sustainable Operations", sustainPara: "Our resorts are equipped with water bottling plants, thus reducing the use of plastic water bottles in our operations."}
    ]
  return (
    <>
        {images.map(image=>(
              <div className='col-lg-3 col-sm-6'>
                <div className='sustain-card pt-xl-5 pt-4 pb-3 px-2'>
                    <div className={'card-image d-flex align-items-center justify-content-center m-auto'}>
                    <img src={image.sustainFeatureImg} alt='Our Gallery' title='Our Gallery' />
                    </div>
                    <div className='sustain-text-box px-xl-2 mt-xl-5 mt-4 rounded-4 ms-auto me-auto text-center'>
                    <h5 className='georgia mb-3'>{image.sustainHeading}</h5>
                    <p>{image.sustainPara}</p>
                    </div>
                </div>
              </div>
        ))}
    </>
  )
}

export default SustainFeatureBox