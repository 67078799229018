import React from 'react'
import { Link } from 'react-router-dom'

function CollateralCards({docTypeIcon, collateralTitle, collateralPara, docDownloadIcon, downloadCollateral}) {
  return (
    <>
        <div className='collateral-card border d-flex align-items-center justify-content-between'>
            <div className='collateral-docs-type d-flex align-items-center justify-content-center'>
                <img src={docTypeIcon} alt={collateralTitle} title={collateralTitle} />
            </div>
            <div className='collateral-text'>
                <h5>{collateralTitle}</h5>
                <p>{collateralPara}</p>
            </div>
            <div className='download-docs'>
                <Link to={downloadCollateral} target='_blank' className='d-flex align-items-center justify-content-center'>
                    <img src={docDownloadIcon} alt="Download" title="Download" />
                </Link>
            </div>
        </div>
    </>
  )
}

export default CollateralCards