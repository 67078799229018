import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';

function SubBrandsSlides({images,gallerySettings}) {
    const settings = {...gallerySettings}
    
  return (
    <Slider {...settings}>
        
            {images.map(image=>(
              <div className='feature-card position-relative overflow-hidden rounded-4'>
                <div className='card-image overflow-hidden'>
                  <img className='w-100' src={image.imageUrl} alt='Our Gallery' title='Our Gallery' />
                </div>
                <div className={`sub-card z-1 position-absolute text-center overflow-hidden smooth cardOneBg ${image.cardBg}`}>
                    <div className='sub-card-title smooth d-flex justify-content-center align-items-center text-white georgia text-uppercase'>
                        <h4 className='m-0'>{image.galleryHeading}</h4>
                    </div>
                    <div className='sub-card-para smooth'>
                        <p>{parse(image.galleryPara)}</p>
                        <div className='card-link mt-md-4 mt-2 mb-3 smooth position-relative'>
                            <Link to="/">View More</Link>
                        </div>
                    </div>
                  
                  
                </div>
            </div>
            ))}
            
    </Slider>
  )
}

export default SubBrandsSlides