import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/our-story-cover.jpg'
import parse from 'html-react-parser'
import fileEdit from '../assets/images/file-edit.svg'
import Purpose from '../assets/images/our-purpose.jpg'
import OurPromise from '../assets/images/our-promise.jpg'
import ValueCard from './ValueCard';
import ExperienceAwait from '../components/ExperienceAwait';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function OurStory() {
    const para = `We are not just hosts but storytellers, crafting authentic, immersive, and enriching experiences that captivate your imagination.Our narrative is sewn together by stories, local anecdotes, and perfect imperfections mirroring the natural habitat our properties call home. In every stay, we kindle wanderlust, inviting you to lose yourself in our wonder-filled destinations. Welcome to a world where hospitality is not just a service but a heartfelt tale tied together with a spirit of generosity and humility.`
  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>OUR STORY</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Our Story</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <ExperienceAwait image={fileEdit} para={para} showLearnMore={false} heading={"Serving heartfelt life tales"}/>





        <section>
            <div className='d-none atmosphere-experience-await section-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='heading-icon'>
                                <img src={fileEdit} title='Atmosphere Living' alt='Atmosphere Living' />
                            </div>
                            <div className='small-heading my-xl-4 my-2 py-2'>
                                <span className='text-uppercase'>WELCOME TO ATMOSPHERE HOTELS & RESORTS</span>
                            </div>
                            <h1 className='text-uppercase georgia heading-space'>A New Experience Awaits</h1>
                        </div>
                        <div className='col-12'>
                            <div className='paragraph pt-3 text-center'>
                                <p>
                                    {parse(para)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    

        <section>
            <div className='our-values'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='dashed-heading position-relative'>
                                <h2 className='georgia text-uppercase'><span>OUR VALUES</span></h2>
                            </div>
                        </div>
                    </div>
                    {/* /row */}

                    <div className='row justify-content-center mt-lg-5 mt-4'>
                        <ValueCard/>
                    </div>

                </div>
            </div>
        </section>


        <section>
            <div className='our-values section-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='dashed-heading position-relative'>
                                <h2 className='georgia text-uppercase'><span>OUR PURPOSE</span></h2>
                            </div>
                        </div>
                    </div>
                    {/* /row */}

                    <div className='row mt-lg-5 mt-4 position-relative'>
                        <div className='col-md-7'>
                            <motion.div key={Math.random()}
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className='feature-left-image'>
                                <img className='w-100' src={Purpose} alt='Purpose' title='Pupropse' />
                            </motion.div>
                        </div>
                        <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='right-feature-text-col position-absolute bottom-0 end-0 top-0 d-flex align-items-center'>
                            <div className='right-feature-wrap bg-white'>
                                <div className='top-head-text text-center'>
                                    <h3 className='georgia'>At ATMOSPHERE HOTELS & RESORTS,</h3>
                                    <span>we will always aim to….</span>
                                </div>
                                <div className='feature-text-para text-center position-relative'>
                                    <p>Curate distinctive spatial experiences inspired by the local culture, thus giving every guest the opportunity to stay immersed in a delightful environment.</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>

                </div>
            </div>
        </section>


        <section>
            <div className='our-values section-padding pt-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='dashed-heading position-relative'>
                                <h2 className='georgia text-uppercase'><span>OUR PROMISE</span></h2>
                            </div>
                        </div>
                    </div>
                    {/* /row */}

                    <div className='row mt-lg-5 mt-4 position-relative'>
                        <div className='col-md-7 offset-md-5'>
                            <motion.div key={Math.random()}
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }} className='feature-left-image'>
                                <img className='w-100' src={OurPromise} alt='Promise' title='Promise' />
                            </motion.div>
                        </div>
                        <motion.div 
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className='right-feature-text-col position-absolute bottom-0 start-0 top-0 d-flex align-items-center'>
                            <div className='right-feature-wrap bg-white'>
                                <div className='top-head-text text-center'>
                                    <h3 className='georgia'>With ATMOSPHERE HOTELS & RESORTS,</h3>
                                    <span>you as a guest can….</span>
                                </div>
                                <div className='feature-text-para text-center position-relative'>
                                    <p>Cherish the charm of thoughtfully curated distinctive experiences that are bound to take you on a memorable journey filled with inspirations drawn from local cultures.</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>

                </div>
            </div>
        </section>

    </>
  )
}

export default OurStory