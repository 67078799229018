import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight} from '@fortawesome/free-solid-svg-icons'
import GallerySlides from './GallerySlides'
import offerImgOne from '../assets/images/offer-img-1.jpg'
import offerImgTwo from '../assets/images/offer-img-2.jpg'
import offerImgThree from '../assets/images/offer-img-3.jpg'
import offerImgFour from '../assets/images/offer-img-4.jpg'
import OfferSlider from './OfferSlider'

function SpecialOffers() {

    
    const gallerySettings = {
        arrow: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerPadding: "460px",
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1700,
              settings: {
                centerPadding: "300px",
              }
            },
            {
              breakpoint: 1399,
              settings: {
                centerPadding: "180px",
              }
            },
            {
              breakpoint: 1199,
              settings: {
                centerPadding: "75px",
              }
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: "0",
              }
            }
          ]
    };

    const images = [
        {imageUrl:offerImgOne, offerSmallText:'KANIFUSHI - MALDIVES', galleryHeading:"Book Direct – Lowest Price Assurance", offerSmallHeading:"20% discount on Booking", galleryPara:"Save 20% on a beautiful picture-postcard holiday in the pristine Lhaviyani Atoll. With assured all-day-long...", link: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms', targetLink: '_blank'},

        {imageUrl:offerImgTwo,offerSmallText:'KANIFUSHI - MALDIVES', galleryHeading:"Celebrate the Season of Splendor", offerSmallHeading:"20% Discount for 4 Nights Booking", galleryPara:"This holiday season, immerse yourself in the warmth of our hospitality and the joyous atmosphere that permeates every corner...", link: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms', targetLink: '_blank'},
        
        {imageUrl:offerImgThree,offerSmallText:'VARU - MALDIVES', offerSmallHeading:'KANIFUSHI - MALDIVES',galleryHeading:"Book Direct – Lowest Price Assurance",offerSmallHeading:"All-Inclusive Holiday- VARU PLAN™", galleryPara:"Experience the ultimate luxury getaway in the Maldives by booking directly with us! As our valued guest...",link: 'https://reservations.varu-atmosphere.com/110184?RatePlanId=3993028&utm_source=website&utm_medium=bookdirect&utm_campaign=book-direct&_gl=1*2l9rrd*_gcl_au*MTAxNTY4MjI0Mi4xNzAxODQxNTQx*_ga*MTM0NDA2MDg5MC4xNzAxODQxNTQx*_ga_B69EZC48X7*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.*_ga_CPMMLXTB86*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.&_ga=2.242058946.116210537.1702378580-1344060890.1701841541', targetLink: '_blank'},

        {imageUrl:offerImgFour,offerSmallText:'VARU - MALDIVES', offerSmallHeading:'KANIFUSHI - MALDIVES',galleryHeading:"Experience Unforgettable Festivities", offerSmallHeading:"20% Discount for 4 Nights Booking",galleryPara:"Indulge in the magic of the festive season at VARU BY Atmosphere, where luxury meets the allure of the Maldives...", link: 'https://reservations.varu-atmosphere.com/110184?RatePlanId=3993028&utm_source=website&utm_medium=bookdirect&utm_campaign=book-direct&_gl=1*2l9rrd*_gcl_au*MTAxNTY4MjI0Mi4xNzAxODQxNTQx*_ga*MTM0NDA2MDg5MC4xNzAxODQxNTQx*_ga_B69EZC48X7*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.*_ga_CPMMLXTB86*MTcwMjM3ODU4MC44LjAuMTcwMjM3ODU4MC42MC4wLjA.&_ga=2.242058946.116210537.1702378580-1344060890.1701841541', targetLink: '_blank'}
    ]
  return (
    <section>
        <div className='special-offers section-padding pt-0'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='hotel-heading-wrap d-flex align-items-center justify-content-between'>
                        <h1 className='text-uppercase georgia heading-space mb-0'>Our Special offers</h1>
                        <Link to='' className='text-dark text-decoration-none view-all' title='Course Link'>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* /container-fluid */}

            <div className='special-offers-slides mt-lg-5 mt-4'>
                <OfferSlider gallerySettings={gallerySettings} images={images} />
            </div>

        </div>
    </section>
  )
}

export default SpecialOffers