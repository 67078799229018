import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight} from '@fortawesome/free-solid-svg-icons'
import HotelsResortsSlides from './HotelsResortsSlides'

function HotelsResorts() {
  return (
    <>
        <section>
            <div className='hotels-resorts section-padding pt-0'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='hotel-heading-wrap d-flex align-items-center justify-content-between'>
                            <h1 className='text-uppercase georgia heading-space mb-0'>Our Hotels &amp; resorts</h1>
                            {/* <Link to='' className='text-dark text-decoration-none view-all text-uppercase d-md-flex d-none' title='Course Link'>see ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* /container-fluid */}
                <HotelsResortsSlides/>
            </div>
        </section>
    </>
  )
}

export default HotelsResorts