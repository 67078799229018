import React, { useState } from "react";
import Iframe from 'react-iframe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-phone-number-input/style.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PhoneInput from 'react-phone-number-input'
import Form from 'react-bootstrap/Form';
import innerCover from '.././assets/images/contact-cover.jpg'
import GetinTouch from '.././assets/images/get-in-touch.svg'
import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function ContactUs() {
    const [value, setValue] = useState();
    
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const handleInputBlur = () => {
        setIsFocused(false);
    };
    const getInTouchPara = "If you have any queries, suggestions, or requests relating to Atmosphere Hotels & Resorts services and experiences, please use the form below to let us know. We shall get back to you at the earliest possible."
    const conditonText = "I confirm I have read and given consent for the above."
  return (
    <>
    <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='Atmosphere' alt='Atmosphere' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>CONTACT US</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='to'>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>
        <section>
            <div className='ask-to-atmosphere section-padding pb-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='ask-form text-center'>
                                <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                                    <img src={GetinTouch} title='Atmosphere Living' alt='Atmosphere Living' />
                                </div>
                                <h2 className='h1 georgia text-uppercase'>GET IN TOUCH</h2>
                                <p className='m-auto mt-3'>{getInTouchPara}</p>

                                <form action='#' method='get' id='demoForm'>
                                    <div className='row mt-xl-5 mt-4'>
                                    
                                        <div className='col-lg-4'>
                                            <div className="mb-4 position-relative">
                                                <input className="form-control" type="text" value={inputValue} onChange={handleInputChange} onFocus={handleInputFocus} onBlur={handleInputBlur} />
                                                <label className={isFocused || inputValue ? 'label-focused' : 'form-label custom-label'}>Your Name</label>
                                            </div>
                                        </div>
                                        {/* /col-lg-4 */}
                                        <div className='col-lg-4'>
                                            <div className='mb-4 position-relative'>
                                            <input className="form-control" type="email" value={inputValue} onChange={handleInputChange} onFocus={handleInputFocus} onBlur={handleInputBlur} />
                                                <label className={isFocused || inputValue ? 'label-focused' : 'form-label custom-label'}>Email Address</label>
                                            </div>
                                            {/* /mb-4 */}
                                        </div>
                                        {/* /col-lg-4 */}
                                        <div className='col-lg-4'>
                                            <div className='mb-4'>
                                                <PhoneInput
                                                international
                                                defaultCountry="RU"
                                                value={value}
                                                className="form-control"
                                                placeholder="Phone Number"
                                                onChange={setValue}/>
                                            </div>
                                            {/* /mb-4 */}
                                        </div>
                                        {/* /col-lg-4 */}
                                        <div className='col-lg-6'>
                                            <div className='mb-4'>
                                                <Form.Select className="form-control" aria-label="Default select example">
                                                    <option>Select Hotel/ Resort</option>
                                                    <option value="1">Atmosphere</option>
                                                    <option value="2">Varu By Atmosphere</option>
                                                    <option value="3">Kanifushi</option>
                                                </Form.Select>
                                            </div>
                                            {/* /mb-4 */}
                                        </div>
                                        {/* /col-lg-4 */}
                                        <div className='col-lg-6'>
                                            <div className='mb-4'>
                                                <Form.Select className="form-control" aria-label="Default select example">
                                                    <option>Select Your Concern</option>
                                                    <option value="1">Booking</option>
                                                    <option value="2">Enquiry</option>
                                                    <option value="3">Complaint</option>
                                                </Form.Select>
                                            </div>
                                            {/* /mb-4 */}
                                        </div>
                                        {/* /col-lg-4 */}
                                        <div className='col-lg-12'>
                                            <div className='mb-4'>
                                            <Form.Control placeholder="Your message here..." as="textarea" rows={4} />
                                            </div>
                                            {/* /mb-4 */}
                                        </div>
                                        {/* /col-lg-4 */}
                                        <div className="gdpr-compliance text-start">
                                            <span className="mb-2 d-flex">GDPR Compliance</span>
                                            <p className="m-0">By giving your confirmation you explicitly give consent for us to store and use this information to service your requests.</p>
                                            <p>If you do not consent we will not store any personal information and will only send an email with the relevant details in order to service your requests.</p>
                                        </div>
                                        <div className="bottom-actions pt-lg-3 d-flex justify-content-between align-items-center">
                                            <div className="check-terms text-start">
                                                <Form.Label for='CheckTerms' className="d-flex position-relative pointer-event">
                                                    <Form.Control type="checkbox" id='CheckTerms'/>
                                                    <div className='custom-check'></div>
                                                    <span>{conditonText}</span>
                                                </Form.Label>
                                            </div>
                                            <div className="form-submit">
                                                <Form.Control type="submit" className="btn btn-primary theme-btn fw-medium text-white text-uppercase" value={'Submit'}/>
                                            </div>
                                        </div>

                                    </div>
                                    {/* /row */}
                                </form>

                            </div>
                            {/* /ask-form */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='contact-via section-padding'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4 col-md-6'>
                            <div className='contact-card bg-white text-center'>
                                <h4 className='text-uppercase georgia'>LOCATE US</h4>
                                <div className='content-text mt-4'>
                                    <p>No 1234, ABC Building, XYZ Street, Dubai, UAE</p>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                        <div className='col-lg-4 col-md-6'>
                            <div className='contact-card bg-white text-center'>
                                <h4 className='text-uppercase georgia'>CALL US</h4>
                                <div className='content-text d-flex align-items-center justify-content-center mt-4'>
                                    <Link to='tel:+919876543218'>+91 9876543218</Link>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                        <div className='col-lg-4 col-md-6'>
                            <div className='contact-card bg-white text-center'>
                                <h4 className='text-uppercase georgia'>EMAIL US</h4>
                                <div className='content-text d-flex align-items-center justify-content-center mt-4'>
                                    <Link to='mailto:info@atmosphere.com'>info@atmosphere.com</Link>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="atmosphere-social-links section-padding pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashed-heading position-relative">
                                <h3 className="mb-0 georgia text-uppercase text-center"><span className="m-auto px-4">FOLLOW US</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="contact-via-social d-flex justify-content-center">
                            <Link to="https://facebook.com/atmospherecore" target='_blank'><FontAwesomeIcon icon={faFacebookF} className='icon-font' /></Link>
                            {/* <Link to="/"><img src={TwitterX} alt="Twitter" title="Twitter"/></Link> */}
                            <Link to="https://www.linkedin.com/company/atmospherecore?originalSubdomain=mv" target='_blank'><FontAwesomeIcon icon={faLinkedinIn} className='icon-font' /></Link>
                            <Link to="https://www.instagram.com/atmospherecore" target='_blank'><FontAwesomeIcon icon={faInstagram} className='icon-font' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="atmosphere-map-location section-padding d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashed-heading position-relative">
                                <h3 className="mb-0 georgia text-uppercase"><span>LOCATE US ON MAP</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="map-location">
                        <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3977.51407709048!2d73.3658826758209!3d4.500908643355437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b407314c3e6303d%3A0x9de11604132a4593!2sVARU%20by%20Atmosphere!5e0!3m2!1sen!2sin!4v1699447193525!5m2!1sen!2sin"
                        width="100%"
                        height="496"
                        id=""
                        className=""
                        display="block"
                        position="relative"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default ContactUs