import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function GallerySlides({images,classview='',gallerySettings}) {
    const settings = {...gallerySettings}
    
  return (
    <Slider {...settings}>
        
            {images.map(image=>(
              <div className='gallery-card position-relative'>
                <div className={`card-image overflow-hidden ${classview}`}>
                  <img className='w-100' src={image.imageUrl} alt='Our Gallery' title='Our Gallery' />
                </div>
                <div className='gallery-text-box position-absolute start-0 end-0 m-auto rounded-4 ms-auto me-auto text-center'>
                  <h4 className='georgia'>{image.galleryHeading}</h4>
                  <p>{image.galleryPara}</p>
                  <div className='view-more-destination d-none'>
                    <Link to='/' className='btn primary theme-btn fw-medium text-white text-uppercase border-theme'>View More <FontAwesomeIcon icon={faAngleRight}/></Link>
                  </div>
                </div>
            </div>
            ))}
            
    </Slider>
  )
}

export default GallerySlides